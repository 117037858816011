import { useSelector } from 'react-redux';
import { QRCodeCanvas } from 'qrcode.react';
import TwoFaForm from '../components/2faForm/TwoFaForm';

const QrCode = () => {
  const { qrCodeUrl } = useSelector((state) => state.userSlice);

  return (
    <div>

      {qrCodeUrl ? (
        <>
          <div>Please scan this QR code with your authentication app and enter the code below</div>
          <QRCodeCanvas value={qrCodeUrl} size={256} level={"H"} />
          <TwoFaForm />

        </>
      ) : (
        <TwoFaForm />

      )}
    </div>
  );
};

export default QrCode;
