import PropTypes from 'prop-types';

const UserItem = ({ item, editUser, confirmDeleteUser }) => {
  return (
    <tr>
      <td>{item.isActive ? 'Yes' : 'No'}</td>
      <td>{item.email}</td>
      <td>{item.username}</td>
      <td>{item.userRole}</td>
      <td>{item.coinCount}</td>
      <td>{item.validWordCount}</td>
      <td>{item.invalidWordCount}</td>
      <td>{item.duplicateWordCount}</td>
      <td>{item.appleId}</td>
      <td>{item.googleId}</td>
      <td>{item.firstLoad ? 'Yes' : 'No'}</td>
      <td>{item.isTwoFA ? 'Yes' : 'No'}</td>
      <td>{item.maxLevel}</td>
      <td>{item.totalScore}</td>
      <td>{item.languageId}</td>
      <td>{item.previousUsername}</td>
      <td>{item.createdAt}</td>
      <td>{item.updatedAt}</td>
      <td>{item.deletedAt}</td>
      <td>
        <button className="btn btn-info btn-sm" onClick={() => editUser(item.id)}>Edit</button>
        <button className="btn btn-danger btn-sm" onClick={() => confirmDeleteUser(item.id)}>Delete</button>
      </td>
    </tr>
  );
};

UserItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      userRole: PropTypes.string.isRequired,
      coinCount: PropTypes.number,
      validWordCount: PropTypes.number,
      invalidWordCount: PropTypes.number,
      duplicateWordCount: PropTypes.number,
      appleId: PropTypes.string,
      googleId: PropTypes.string,
      isActive: PropTypes.bool,
      firstLoad: PropTypes.bool,
      isTwoFA: PropTypes.bool,
      maxLevel: PropTypes.number,
      totalScore: PropTypes.number,
      languageId: PropTypes.number,
      previousUsername: PropTypes.string,
      createdAt: PropTypes.any,
      updatedAt: PropTypes.any,
      deletedAt: PropTypes.any,
  }).isRequired,
  editUser: PropTypes.func.isRequired,
  confirmDeleteUser: PropTypes.func.isRequired,
};

export default UserItem;
