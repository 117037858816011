import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AdminPanel = () => {
  return (
    <Container className="mt-5">
      <Row className="text-center mb-4">
        <Col>
          <h1>Welcome to Wordamundo!</h1>
          <p className="text-muted">
            Explore the various sections below to manage the content.
          </p>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Manage Levels</Card.Title>
              <Card.Text>Manage levels in the app, add new ones, or edit existing ones.</Card.Text>
              <Button as={Link} to="/argusma-words-panel/level" variant="primary">
                Go to Levels
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Manage Users</Card.Title>
              <Card.Text>Manage user accounts, add or edit user details.</Card.Text>
              <Button as={Link} to="/argusma-words-panel/user" variant="primary">
                Go to Users
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Manage Languages</Card.Title>
              <Card.Text>Manage languages used in the app, add new ones or edit existing ones.</Card.Text>
              <Button as={Link} to="/argusma-words-panel/language" variant="primary">
                Go to Languages
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Manage Words</Card.Title>
              <Card.Text>Manage words in the app, add new ones or edit existing ones.</Card.Text>
              <Button as={Link} to="/argusma-words-panel/word" variant="primary">
                Go to Words
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Manage Stages</Card.Title>
              <Card.Text>Manage stages in the app, add new ones or edit existing ones.</Card.Text>
              <Button as={Link} to="/argusma-words-panel/stage" variant="primary">
                Go to Stages
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Manage Letter Groups</Card.Title>
              <Card.Text>Manage letter groups in the app, add new ones or edit existing ones.</Card.Text>
              <Button as={Link} to="/argusma-words-panel/letter-group" variant="primary">
                Go to Letter Groups
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminPanel;
