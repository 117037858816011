import PropTypes from 'prop-types';

const WordItem = ({ item, edit, confirmDelete }) => {
  return (
    <tr>
      <td>{item.isActive ? 'Yes' : 'No'}</td>
      <td>{item.letters}</td>
      <td>{item.idLanguage}</td>
      <td>
        <button className="btn btn-info btn-sm" onClick={() => edit(item.id)}>Edit</button>
        <button className="btn btn-danger btn-sm" onClick={() => confirmDelete(item.id)}>Delete</button>
      </td>
    </tr>
  );
};

WordItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    letters: PropTypes.string.isRequired,
    idLanguage: PropTypes.number.isRequired,
  }).isRequired,
  edit: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default WordItem;
