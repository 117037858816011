import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaLevelUpAlt, FaMap, FaBolt, FaUsers, FaChartLine, FaBook } from 'react-icons/fa';
import Footer from '../components/footer/Footer';
import PublicNavbar from '../components/publicNavbar/PublicNavbar';
import logo from '../assets/icon.png';

const Home = () => {
  return (
    <Container fluid className="p-0 mt-4">
      <PublicNavbar />

      {/* Hero Section */}
      <div
        style={{
          background: 'linear-gradient(135deg, #1e3c72, #2a5298)',
          color: 'white',
          padding: '100px 20px',
          textAlign: 'center',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
        }}
      >
        <img
          src={logo}
          alt="Wordamundo Logo"
          style={{
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            borderRadius: '15px',
            marginBottom: '20px',
            transition: 'transform 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.transform = 'scale(1.2)')}
          onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
        />
        <h1
          className="display-3 mb-4"
          style={{
            fontWeight: '700',
            letterSpacing: '2px',
            textShadow: '2px 2px 8px rgba(0,0,0,0.3)',
            animation: 'fadeIn 2s ease-in-out',
          }}
        >
          Wordamundo
        </h1>
        <p
          className="lead mb-4"
          style={{
            fontSize: '1.5rem',
            maxWidth: '800px',
            margin: '0 auto',
            lineHeight: '1.8',
          }}
        >
          Embark on a fun language learning journey with Wordamundo. Choose your
          game mode, explore the interactive map, and level up your language
          skills!
        </p>
      </div>

      {/* Friends and Family Testing Section */}
      <div
        style={{
          background: '#f1f1f1',
          padding: '60px 20px',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            width: '100%',
            maxWidth: '640px',
            boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScCbXjTytpETQEwZoUwCZyps4qrNuAx0Nnwuq0PHNoWeljtlQ/viewform?embedded=true"
            width="100%"
            height="700"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            style={{
              border: 'none',
            }}
          >
            Loading…
          </iframe>
        </div>
      </div>

      {/* Download Section */}
      <div
        style={{
          background: '#f8f9fa',
          padding: '80px 20px',
          textAlign: 'center',
        }}
      >
        <h2 className="mb-4" style={{ fontWeight: '600', color: '#2a5298' }}>
          Download Wordamundo
        </h2>
        <p
          className="mb-4"
          style={{
            fontSize: '1.2rem',
            maxWidth: '600px',
            margin: '0 auto',
            lineHeight: '1.8',
            color: '#333',
          }}
        >
          Wordamundo combines language learning with entertaining gameplay and
          social features.
        </p>
        <Button
          variant="secondary"
          size="lg"
          style={{
            background: '#ccc',
            border: 'none',
            padding: '10px 30px',
            borderRadius: '30px',
            cursor: 'not-allowed',
          }}
          disabled
        >
          Coming Soon
        </Button>
      </div>

      {/* Features Section */}
      <Row className="d-flex justify-content-center my-5">
        {[
          {
            icon: <FaLevelUpAlt size={50} />,
            title: 'Game Modes',
            text: 'Play in Ranked Mode to earn coins, compete globally, and unlock levels, or switch to Practice Mode for a relaxed experience without time limits.',
          },
          {
            icon: <FaBolt size={50} />,
            title: 'Super Powers',
            text: 'Use tools like Peek Words, Extra Time, and New Letters strategically to overcome challenging levels.',
          },
          {
            icon: <FaMap size={50} />,
            title: 'Interactive Map',
            text: 'Navigate through levels and access bonus challenges via the interactive map.',
          },
          {
            icon: <FaUsers size={50} />,
            title: 'Stats Comparison',
            text: 'Compare your stats with friends, including levels completed, unique words, and top scores.',
          },
          {
            icon: <FaChartLine size={50} />,
            title: 'Records',
            text: 'Compete in World Rankings or with friends to climb the leaderboards.',
          },
          {
            icon: <FaBook size={50} />,
            title: 'My Dictionary',
            text: 'Track your progress and expand your vocabulary with My Dictionary. Discover your Most Valuable Words and explore your personal collection!',
          },
        ].map((item, idx) => (
          <Col md={4} className="mb-4" key={idx}>
            <Card
              className="shadow-sm h-100 text-center"
              style={{
                borderRadius: '15px',
                overflow: 'hidden',
                transition: 'transform 0.3s ease',
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = 'translateY(-10px)')
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.transform = 'translateY(0)')
              }
            >
              <Card.Body>
                <div
                  style={{
                    color: '#2a5298',
                    marginBottom: '15px',
                  }}
                >
                  {item.icon}
                </div>
                <Card.Title style={{ fontWeight: '600', fontSize: '1.5rem' }}>
                  {item.title}
                </Card.Title>
                <Card.Text style={{ fontSize: '1rem', color: '#666' }}>
                  {item.text}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Footer />
    </Container>
  );
};

export default Home;
