import { createAsyncThunk } from '@reduxjs/toolkit';
import apiAxiosWithCredentials from '../../../../config/axios.config';

export const asyncGetUsers = createAsyncThunk(
  'user/asyncGetUsers',
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/users/cms/search/?page=${currentPage - 1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncUpdateUser = createAsyncThunk(
  'user/asyncUpdateUser',
  async ({ id, updatedUser }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(`/arguscreative/wordamundo/users/cms/${id}`, updatedUser);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncDeleteUser = createAsyncThunk(
  'user/asyncDeleteUser',
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.delete(`/arguscreative/wordamundo/users/cms/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
