import { Container } from 'react-bootstrap';
import Footer from '../components/footer/Footer';
import PublicNavbar from '../components/publicNavbar/PublicNavbar';

const PrivacyPolicy = () => {
  return (
    <Container fluid className="p-0">
      <PublicNavbar />
      {/* Hero Section */}
      <div
        style={{
          background: 'linear-gradient(135deg, #1e3c72, #2a5298)',
          color: 'white',
          padding: '100px 20px',
          textAlign: 'center',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
        }}
      >
        <h1
          style={{
            fontWeight: '700',
            fontSize: '2.5rem',
            textShadow: '2px 2px 8px rgba(0,0,0,0.3)',
          }}
        >
          Privacy Policy
        </h1>
        <p
          style={{
            marginTop: '10px',
            fontSize: '1.2rem',
            fontWeight: '500',
          }}
        >
          Last updated: January 15, 2025
        </p>
      </div>

      {/* Content Section */}
      <Container
        className="my-5"
        style={{
          padding: '40px 20px',
          maxWidth: '800px',
          backgroundColor: '#f8f9fa',
          borderRadius: '15px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        {[
          {
            title: '1. Introduction',
            content:
              'Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use the Wordamundo app and website.',
          },
          {
            title: '2. Information We Collect',
            content:
              'We may collect personal information that you provide to us directly, such as your name, email address, and usage data, as well as data collected automatically when you use our services.',
          },
          {
            title: '3. Use of Information',
            content:
              'The information we collect is used to provide, maintain, and improve our services, as well as to personalize your experience with Wordamundo.',
          },
          {
            title: '4. Sharing of Information',
            content:
              'We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights.',
          },
          {
            title: '5. Security of Your Information',
            content:
              'We implement security measures to protect your personal information from unauthorized access, use, or disclosure.',
          },
          {
            title: '6. Changes to Privacy Policy',
            content:
              'We may update this Privacy Policy from time to time. We will notify users of any changes by updating the date at the top of this document.',
          },
          {
            title: '7. Contact Us',
            content:
              'If you have any questions about this Privacy Policy, please contact us at michael@arguscreativeapps.com',
          },
          {
            title: '8. In-App Purchases and Payment Information',
            content: (
              <>
                <p>
                  Wordamundo allows players to purchase in-app coins to unlock superpowers and enhance their gameplay. These purchases are optional and designed to improve the gaming experience.
                </p>
                <p>
                  Coins can be used for the following:
                </p>
                <ul style={{ paddingLeft: '20px', marginBottom: '20px' }}>
                  <li>
                    <strong>Peek Words:</strong> Review words you’ve already entered to avoid duplicates. Cost: 1 coin.
                  </li>
                  <li>
                    <strong>Extra Time:</strong> Add 10 seconds to your timer in Ranked Mode. Cost: 3 coins.
                  </li>
                  <li>
                    <strong>New Letters:</strong> Refresh your letter group while keeping essential requirements intact. Cost: 5 coins.
                  </li>
                </ul>
                <p>
                  Purchases are processed through third-party payment providers (e.g., Google Play Store) and are subject to their terms and conditions. Ensure that your account settings allow for authorized transactions.
                </p>
                <p>
                  <strong>Note:</strong> All sales are final, and refunds are granted only under exceptional circumstances at our sole discretion. Resetting Demo Money in the Friends and Family version will delete all accumulated coins.
                </p>
              </>
            ),
          }

        ].map((section, index) => (
          <section key={index} className="mb-4">
            <h2
              style={{
                fontSize: '1.8rem',
                fontWeight: '700',
                color: '#2a5298',
                marginBottom: '15px',
              }}
            >
              {section.title}
            </h2>
            <p
              style={{
                fontSize: '1.2rem',
                lineHeight: '1.8',
                color: '#333',
                marginBottom: '20px',
              }}
            >
              {section.content}
            </p>
          </section>
        ))}
      </Container>

      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
