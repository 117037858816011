import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LetterGroupLevelList from './LetterGroupLevelList';
import ConfirmationPopup from '../popUp/ConfirmationPopup';
import { clearFilters, setCurrentPage, setFilters } from '../../redux/actions/admin/letterGroupLevel/letterGroupLevel.sync.actions';
import { asyncDeleteLetterGroupLevel, asyncGetLetterGroupLevels } from '../../redux/actions/admin/letterGroupLevel/letterGroupLevel.async.actions';

const LetterGroupLevelContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { letterGroupLevels, currentPage, totalPages, filters, isLoading, error, successMessage } = useSelector((state) => state.letterGroupLevelAdminSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [letterGroupLevelToDelete, setLetterGroupLevelToDelete] = useState(null);

  useEffect(() => {
    dispatch(asyncGetLetterGroupLevels({ currentPage, filters }));
  }, [dispatch, currentPage, filters]);

  const handleFilterChange = (filter, value) => {
    dispatch(setFilters({ ...filters, [filter]: value }));
    dispatch(setCurrentPage(1));
  };

  const clearAllFilters = () => {
    dispatch(clearFilters());
    dispatch(setCurrentPage(1));
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const edit = (id) => {
    navigate(`/argusma-words-panel/letter-group-level/update/${id}`);
  };

  const confirmDelete = (id) => {
    setLetterGroupLevelToDelete(id);
    setShowConfirmation(true);
  };

  const handleDeleteLetterGroupLevel = () => {
    if (letterGroupLevelToDelete) {
      dispatch(asyncDeleteLetterGroupLevel(letterGroupLevelToDelete))
        .then(() => {
          dispatch(asyncGetLetterGroupLevels({ currentPage, filters }));
          setShowConfirmation(false);
          setLetterGroupLevelToDelete(null);
        });
    }
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setLetterGroupLevelToDelete(null);
  };

  return (
    <div className="container mt-4">
      <h2>Letter Group-Level</h2>

      {isLoading && <p>Loading...</p>}
      {error && <p className="text-danger">Error: {error}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}

      <div className="d-flex justify-content-start mb-3">
        <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
      </div>

      <div className="mb-3">
        <label htmlFor="filterUsed" className="form-label">Filter by Used</label>
        <select
          id="filterUsed"
          className="form-select"
          value={filters.used}
          onChange={(e) => handleFilterChange('used', e.target.value)}
        >
          <option value="">All</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="filterIdLetterGroup" className="form-label">Filter by Letter Group ID</label>
        <input
          type="number"
          id="filterIdLetterGroup"
          className="form-control"
          value={filters.idLetterGroup}
          onChange={(e) => handleFilterChange('idLetterGroup', e.target.value)}
          placeholder="Enter Letter Group ID"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="filterIdLevel" className="form-label">Filter by Level ID</label>
        <input
          type="number"
          id="filterIdLevel"
          className="form-control"
          value={filters.idLevel}
          onChange={(e) => handleFilterChange('idLevel', e.target.value)}
          placeholder="Enter Level ID"
        />
      </div>

      <table className="table table-striped table-bordered custom-table">
        <thead className="thead-dark">
          <tr>
            <th>Is it Used?</th>
            <th>Letter Group ID</th>
            <th>Level ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <LetterGroupLevelList list={letterGroupLevels} edit={edit} confirmDelete={confirmDelete} />
        </tbody>
      </table>

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1}>Prev</button>
        <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button>
      </div>
      <div className="mt-3">
        Page {currentPage} of {totalPages}
      </div>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleDeleteLetterGroupLevel}
        onCancel={cancelDelete}
      />
    </div>
  );
};

export default LetterGroupLevelContainer;
