import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncCreateWord } from '../../redux/actions/admin/word/word.async.actions';

const NewWord = () => {
  const [formData, setFormData] = useState({
    letters: '',
    idLanguage: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(asyncCreateWord(formData)).unwrap();
      navigate('/word');
    } catch (err) {
      console.error('Failed to add word:', err);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Add New Word</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="letters" className="form-label">Letters</label>
          <input
            required
            type="text"
            className="form-control"
            id="letters"
            name="letters"
            value={formData.letters}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="idLanguage" className="form-label">Language ID</label>
          <input
            required
            type="number"
            className="form-control"
            id="idLanguage"
            name="idLanguage"
            value={formData.idLanguage}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Word</button>
      </form>
    </div>
  );
};

export default NewWord;
