import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncGetUsers, asyncDeleteUser } from '../../redux/actions/admin/user/user.async.actions';
import { setFilters, clearFilters, setCurrentPage } from '../../redux/actions/admin/user/user.sync.actions';
import UserList from './UserList';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UserContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, currentPage, totalPages, filters, isLoading, error, successMessage } = useSelector((state) => state.userAdminSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    dispatch(asyncGetUsers({ currentPage, filters }));

  }, [dispatch, currentPage, filters]);

  const handleFilterChange = (filter, value) => {
    const updatedFilters = { ...filters, [filter]: value };
    dispatch(setFilters(updatedFilters));
    dispatch(setCurrentPage(1));
    dispatch(asyncGetUsers({ currentPage: 1, filters: updatedFilters }));
  };

  const clearAllFilters = () => {
    dispatch(clearFilters());
    dispatch(setCurrentPage(1));
    dispatch(asyncGetUsers({ currentPage: 1, filters: {} }));
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const editUser = (id) => {
    navigate(`/argusma-words-panel/user/update/${id}`);
  };

  const confirmDeleteUser = (id) => {
    setUserToDelete(id);
    setShowConfirmation(true);
  };

  const handleDeleteUser = () => {
    if (userToDelete) {
      dispatch(asyncDeleteUser(userToDelete))
        .then(() => {
          dispatch(asyncGetUsers({ currentPage, filters }));
          setShowConfirmation(false);
          setUserToDelete(null);
        });
    }
  };

  const cancelDeleteUser = () => {
    setShowConfirmation(false);
    setUserToDelete(null);
  };

  return (
    <div className="container mt-4">
      <h2>Users</h2>

      {isLoading && <p>Loading...</p>}
      {error && <p className="text-danger">Error: {error}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}

      <div className="d-flex justify-content-start mb-3">
        <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
      </div>

      <div className="mb-3">
        <label htmlFor="filterEmail" className="form-label">Filter by Email</label>
        <input
          type="text"
          id="filterEmail"
          className="form-control"
          value={filters.email || ''}
          onChange={(e) => handleFilterChange('email', e.target.value)}
          placeholder="Enter email"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="filterUsername" className="form-label">Filter by Username</label>
        <input
          type="text"
          id="filterUsername"
          className="form-control"
          value={filters.username || ''}
          onChange={(e) => handleFilterChange('username', e.target.value)}
          placeholder="Enter username"
        />
      </div>

      <UserList
        list={users}
        editUser={editUser}
        confirmDeleteUser={confirmDeleteUser}
      />

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1 || isLoading}>Prev</button>
        <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages || isLoading}>Next</button>
      </div>
      <div className="mt-3">
        Page {currentPage} of {totalPages}
      </div>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleDeleteUser}
        onCancel={cancelDeleteUser}
      />
    </div>
  );
};

export default UserContainer;
