import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'universal-cookie';

import { asyncLogin, asyncVerifyTwoFA } from "../../actions/common/user.async.actions";


const cookies = new Cookies();

const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    wdmToken: null,
    wdmstToken: null,
    isLoggedIn: false,
    is2FaVerified: false,
    isTwoFaEnabled: false,
    isLoading: false,
    error: null,
    qrCodeUrl: "",
  },
  reducers: {
    logout: (state) => {
      state.username = "";
      state.wdmToken = null;
      state.wdmstToken = null;
      state.isLoggedIn = false;
      state.is2FaVerified = false;
      state.isTwoFaEnabled = false;
      state.qrCodeUrl = "";
      cookies.remove('wdm', { path: '/' });
      cookies.remove('wdmst', { path: '/' });
    },
    setTwoFaEnabled: (state, action) => {
      state.isTwoFaEnabled = action.payload;
    },
    setWdmToken: (state, action) => {
      state.wdmToken = action.payload;
      cookies.set('wdm', action.payload, { path: '/', secure: true, sameSite: 'strict' });
    },
    setWdmstToken: (state, action) => {
      state.wdmstToken = action.payload;
      cookies.set('wdmst', action.payload, { path: '/', secure: true, sameSite: 'strict' });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncLogin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncLogin.fulfilled, (state, action) => {
        state.username = action.payload.username;
        state.qrCodeUrl = action.payload.qrCodeUrl; 
        state.isTwoFaEnabled = action.payload.twoFaEnabled;
        state.is2FaVerified = false;
        state.isLoggedIn = !action.payload.twoFaEnabled;
        state.isLoading = false;

        if (!action.payload.twoFaEnabled) {
          const {accessToken} = action.payload.accessToken
          state.wdmToken = accessToken;
          state.wdmstToken = action.payload.refreshToken;
          cookies.set('wdm', action.payload.accessToken, { path: '/', secure: true, sameSite: 'strict' });
          cookies.set('wdmst', action.payload.refreshToken, { path: '/', secure: true, sameSite: 'strict' });
        }
      })
      .addCase(asyncLogin.rejected, (state) => {
        state.error = 'User or password not correct'; 
        state.isLoading = false;
      })
      .addCase(asyncVerifyTwoFA.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncVerifyTwoFA.fulfilled, (state, action) => {
        state.wdmToken = action.payload.token;
        state.is2FaVerified = true;
        state.isLoggedIn = true;
        state.isLoading = false;
        cookies.set('wdm', action.payload.token, { path: '/', secure: true, sameSite: 'strict' });
      })
      .addCase(asyncVerifyTwoFA.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const { logout, setTwoFaEnabled, setWdmToken, setWdmstToken } = userSlice.actions;

export default userSlice.reducer;
