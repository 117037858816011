import { Container } from 'react-bootstrap';
import Footer from '../components/footer/Footer';
import PublicNavbar from '../components/publicNavbar/PublicNavbar';

const TermsOfService = () => {
  return (
    <Container fluid className="p-0">
      <PublicNavbar />
      {/* Hero Section */}
      <div
        style={{
          background: 'linear-gradient(135deg, #1e3c72, #2a5298)',
          color: 'white',
          padding: '100px 20px',
          textAlign: 'center',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
        }}
      >
        <h1
          style={{
            fontWeight: '700',
            fontSize: '2.5rem',
            textShadow: '2px 2px 8px rgba(0,0,0,0.3)',
          }}
        >
          Terms of Service
        </h1>
        <p
          style={{
            marginTop: '10px',
            fontSize: '1.2rem',
            fontWeight: '500',
          }}
        >
          Last updated: January 15, 2025
        </p>

      </div>

      {/* Content Section */}
      <Container
        className="my-5"
        style={{
          padding: '40px 20px',
          maxWidth: '800px',
          backgroundColor: '#f8f9fa',
          borderRadius: '15px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        {[
          {
            title: '1. Introduction',
            content:
              'Welcome to Wordamundo. By using our app and website, you agree to comply with and be bound by these Terms of Service.',
          },
          {
            title: '2. User Obligations',
            content:
              'As a user of Wordamundo, you are responsible for ensuring that your use of the app and its content is lawful and in compliance with these terms.',
          },
          {
            title: '3. Intellectual Property',
            content:
              'All content provided in the app, including text, graphics, logos, and images, is the property of Wordamundo and protected by copyright laws.',
          },
          {
            title: '4. Changes to Terms',
            content:
              'We may update these Terms of Service at any time. Any changes will be communicated to users and will take effect upon posting.',
          },
          {
            title: '5. Contact Us',
            content:
              'If you have any questions about these Terms, please contact us at michael@arguscreativeapps.com',
          },
          {
            title: '6. In-App Purchases',
            content: (
              <>
                <p>
                  Wordamundo allows players to purchase in-app coins to unlock superpowers and enhance their gameplay. These purchases are designed to improve the gaming experience and are entirely optional.
                </p>
                <p>
                  Coins can be used for the following:
                </p>
                <ul style={{ paddingLeft: '20px', marginBottom: '20px' }}>
                  <li>
                    <strong>Peek Words:</strong> Review words you’ve already entered to avoid duplicates. Cost: 1 coin.
                  </li>
                  <li>
                    <strong>Extra Time:</strong> Add 10 seconds to your timer in Ranked Mode. Cost: 3 coins.
                  </li>
                  <li>
                    <strong>New Letters:</strong> Refresh your letter group while keeping essential requirements intact. Cost: 5 coins.
                  </li>
                </ul>
                <p>
                  Purchases are processed through third-party payment providers (e.g., Google Play Store) and are subject to their respective terms and conditions. It is your responsibility to ensure that your account settings allow for authorized transactions.
                </p>
                <p>
                  <strong>Refund Policy:</strong> All sales are final. Refunds will only be provided under exceptional circumstances and at our sole discretion, in compliance with the app store's refund policy.
                </p>
                <p>
                  <strong>Demo Money (Friends and Family Version):</strong> If you are using the Friends and Family version, Demo Money is available to test the store. Resetting Demo Money will delete all accumulated coins, so use it wisely.
                </p>
              </>
            ),
          }

        ].map((section, index) => (
          <section key={index} className="mb-4">
            <h2
              style={{
                fontSize: '1.8rem',
                fontWeight: '700',
                color: '#2a5298',
                marginBottom: '15px',
              }}
            >
              {section.title}
            </h2>
            <p
              style={{
                fontSize: '1.2rem',
                lineHeight: '1.8',
                color: '#333',
                marginBottom: '20px',
              }}
            >
              {section.content}
            </p>
          </section>
        ))}
      </Container>

      <Footer />
    </Container>
  );
};

export default TermsOfService;
