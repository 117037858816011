import { useState, useEffect } from 'react';

import RoutesContainer from "../routes/RoutesContainer"
import { useIdleTimer } from 'react-idle-timer';
import useSessionContext from '../hooks/useSessionContext';

const Wordamundo = () => {
    const [remainingTime, setRemainingTime] = useState(null);

    const { logout } = useSessionContext()

    const {
        getRemainingTime,
    } = useIdleTimer({
        timeout: 1000 * 60 * 15, // 15 minutes in miliseconds
        logout
    });


    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingTime(getRemainingTime());
        }, 1000);

        if (remainingTime === 0) {
            logout()
        }
        return () => clearInterval(intervalId);
    }, [remainingTime, getRemainingTime, logout]);

    return (
        <div className="container-fluid">
            <RoutesContainer />
        </div>
    );
};

export default Wordamundo