import { createAsyncThunk } from "@reduxjs/toolkit";
import apiAxiosWithCredentials from "../../../../config/axios.config";

export const asyncGetStages = createAsyncThunk(
  "stage/asyncGetStages",
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/stages/cms/search/?page=${currentPage - 1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncCreateStage = createAsyncThunk(
  "stage/asyncCreateStage",
  async (newStage, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.post(
        "/arguscreative/wordamundo/stages/cms",
        newStage
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncUpdateStage = createAsyncThunk(
  "stage/asyncUpdateStage",
  async ({ id, updatedStage }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(
        `/arguscreative/wordamundo/stages/cms/${id}`,
        updatedStage
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncDeleteStage = createAsyncThunk(
  "stage/asyncDeleteStage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.delete(
        `/arguscreative/wordamundo/stages/cms/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
