import { createAsyncThunk } from "@reduxjs/toolkit";
import apiAxiosWithCredentials from "../../../../config/axios.config";

export const asyncGetLetterGroups = createAsyncThunk(
  "letterGroup/asyncGetLetterGroups",
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/letter-groups/cms/search/?page=${currentPage - 1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncCreateLetterGroup = createAsyncThunk(
  "letterGroup/asyncCreateLetterGroup",
  async (newLetterGroup, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.post(
        "/arguscreative/wordamundo/letter-groups/cms",
        {
          stageId: newLetterGroup.idStage,
          languageId: newLetterGroup.idLanguage,
          letters: newLetterGroup.letters
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncUpdateLetterGroup = createAsyncThunk(
  "letterGroup/asyncUpdateLetterGroup",
  async ({ id, updatedLetterGroup }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(
        `/arguscreative/wordamundo/letter-groups/cms/${id}`,
        {
          stageId: updatedLetterGroup.idStage,
          languageId: updatedLetterGroup.idLanguage,
          letters: updatedLetterGroup.letters
        }      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncDeleteLetterGroup = createAsyncThunk(
  "letterGroup/asyncDeleteLetterGroup",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.delete(
        `/arguscreative/wordamundo/letter-groups/cms/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
