import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

export const SessionContext = createContext();

const cookies = new Cookies();

export const SessionProvider = ({ children }) => {
    const [wdmToken, setWdmToken] = useState(null);
    const [wdmstToken, setWdmstToken] = useState(null);
    const [user, setUser] = useState({
        username: ""
    });

    const login = (token, expiresAt) => {
        setWdmToken(token);
        cookies.set('wdm', token, { expires: expiresAt });
        cookies.remove('wdmst');
        setWdmstToken(null);
    };

    const twoFaToken = (token, expiresAt) => {
        setWdmstToken(token);
        cookies.set('wdmst', token, { expires: expiresAt, path: `/api/wcs/set-qrcode` });
    };

    const logout = () => {
        setWdmstToken(null);
        setWdmToken(null);
        cookies.remove('wdm');
        cookies.remove('wdmst');
        window.location.replace("/");
    };

    useEffect(() => {
        const authToken = cookies.get('wdm');
        if (authToken) {
            setWdmToken(authToken);
        }

        const loginToken = cookies.get('wdmst');
        if (loginToken) {
            setWdmstToken(loginToken);
        }
    }, []); 

    return (
        <SessionContext.Provider value={{ wdmToken, wdmstToken, login, logout, twoFaToken, user, setUser }}>
            {children}
        </SessionContext.Provider>
    );
};

SessionProvider.propTypes = {
    children: PropTypes.node.isRequired
};
