import PropTypes from 'prop-types';
import LetterGroupItem from './LetterGroupItem';

const LetterGroupList = ({ list, edit, confirmDelete }) => {
  return (
    <>
      {list.map((item) => (
        <LetterGroupItem
          key={item.id}
          item={item}
          edit={edit}
          confirmDelete={confirmDelete}
        />
      ))}
    </>
  );
};

LetterGroupList.propTypes = {
  list: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default LetterGroupList;
