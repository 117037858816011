import  { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncCreateLetterGroup } from '../../redux/actions/admin/letterGroup/letterGroup.async.actions';

const NewLetterGroup = () => {
  const [formData, setFormData] = useState({
    letters: '',
    languageId: '',
    stageId: ''
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(asyncCreateLetterGroup(formData)).unwrap();
      navigate('/argusma-words-panel/letter-group');
    } catch (err) {
      console.error('Failed to add letter group:', err);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Add New Letter Group</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="letters" className="form-label">Letters</label>
          <input required type="text" className="form-control" id="letters" name="letters" value={formData.letters} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="languageId" className="form-label">Language ID</label>
          <input required type="number" className="form-control" id="languageId" name="languageId" value={formData.languageId} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="stageId" className="form-label">Stage ID</label>
          <input required type="number" className="form-control" id="stageId" name="stageId" value={formData.stageId} onChange={handleChange} />
        </div>
        <button type="submit" className="btn btn-primary">Add Letter Group</button>
      </form>
    </div>
  );
};

export default NewLetterGroup;
