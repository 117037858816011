import axios from 'axios';
import { API_URL } from '.';

const apiAxiosWithCredentials = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

apiAxiosWithCredentials.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshResponse = await apiAxiosWithCredentials.get('/auth/refresh-token', {
          withCredentials: true,
        });

        const { accessToken } = refreshResponse.data;

        apiAxiosWithCredentials.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

        return apiAxiosWithCredentials(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default apiAxiosWithCredentials;
