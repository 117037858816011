import WordItem from './WordItem';
import PropTypes from 'prop-types';

const WordList = ({ list, edit, confirmDelete }) => {
  return (
    <>
      {list.map((item) => (
        <WordItem
          key={item.id}
          item={item}
          edit={edit}
          confirmDelete={confirmDelete}
        />
      ))}
    </>
  );
};

WordList.propTypes = {
  list: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default WordList;
