import { createAsyncThunk } from '@reduxjs/toolkit';
import apiAxiosWithCredentials from '../../../../config/axios.config';

export const asyncGetUserLevels = createAsyncThunk(
  'userLevel/asyncGetUserLevels',
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/user-levels/cms/search/?page=${currentPage - 1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncUpdateUserLevel = createAsyncThunk(
  'userLevel/asyncUpdateUserLevel',
  async ({ id, updatedUserLevel }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(`/arguscreative/wordamundo/user-levels/cms/${id}`, updatedUserLevel);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncGetUserLevelById = createAsyncThunk(
  'userLevel/asyncGetUserLevelById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.get(`/arguscreative/wordamundo/user-levels/cms/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncDeleteUserLevel = createAsyncThunk(
  'userLevel/asyncDeleteUserLevel',
  async (id, { rejectWithValue }) => {
    try {
      await apiAxiosWithCredentials.delete(`/arguscreative/wordamundo/user-levels/cms/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
