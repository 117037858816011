import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { asyncGetUserLevelById, asyncUpdateUserLevel } from '../../redux/actions/admin/userLevel/userLevel.async.actions';

const UpdateUserLevel = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.userLevelAdminSlice);
  const [formData, setFormData] = useState({
    completed: false,
    locked: false,
    validWords: '',
    invalidWords: '',
    duplicateWords: '',
    wordsPerSecond: '',
    highestScore: '',
    mostValuableWordEntered: '',
    idLevel: '',
    idUser: ''
  });

  useEffect(() => {
    if (id) {
      dispatch(asyncGetUserLevelById(id))
        .unwrap()
        .then((data) => setFormData(data))
        .catch((error) => console.error('Failed to fetch user level:', error));
    }
  }, [dispatch, id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(asyncUpdateUserLevel({ id, updatedUserLevel: formData }))
      .unwrap()
      .then(() => navigate('/user-level'))
      .catch((error) => console.error('Failed to update user level:', error));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <h2>Edit User Level</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="completed" className="form-label">Is Complete?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="completed"
            name="completed"
            checked={formData.completed}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="locked" className="form-label">Is Locked?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="locked"
            name="locked"
            checked={formData.locked}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="validWords" className="form-label">Valid Words</label>
          <input
            type="number"
            className="form-control"
            id="validWords"
            name="validWords"
            value={formData.validWords || ''}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="invalidWords" className="form-label">Invalid Words</label>
          <input
            type="number"
            className="form-control"
            id="invalidWords"
            name="invalidWords"
            value={formData.invalidWords || ''}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="duplicateWords" className="form-label">Duplicate Words</label>
          <input
            type="number"
            className="form-control"
            id="duplicateWords"
            name="duplicateWords"
            value={formData.duplicateWords || ''}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="wordsPerSecond" className="form-label">Words per Second</label>
          <input
            type="number"
            className="form-control"
            id="wordsPerSecond"
            name="wordsPerSecond"
            value={formData.wordsPerSecond || ''}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="highestScore" className="form-label">Highest Score</label>
          <input
            type="number"
            className="form-control"
            id="highestScore"
            name="highestScore"
            value={formData.highestScore || ''}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="mostValuableWordEntered" className="form-label">Most Valuable Word Entered</label>
          <input
            type="text"
            className="form-control"
            id="mostValuableWordEntered"
            name="mostValuableWordEntered"
            value={formData.mostValuableWordEntered || ''}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="idLevel" className="form-label">Level ID</label>
          <input
            type="number"
            className="form-control"
            id="idLevel"
            name="idLevel"
            value={formData.idLevel || ''}
            readOnly
          />
        </div>

        <div className="mb-3">
          <label htmlFor="idUser" className="form-label">User ID</label>
          <input
            type="number"
            className="form-control"
            id="idUser"
            name="idUser"
            value={formData.idUser || ''}
            readOnly
          />
        </div>

        <button type="submit" className="btn btn-primary">Update</button>
      </form>
    </div>
  );
};

export default UpdateUserLevel;
