import { createSlice } from '@reduxjs/toolkit';
import { 
  asyncGetUserLevels,
  asyncGetUserLevelById,
  asyncUpdateUserLevel,
  asyncDeleteUserLevel
} from '../../actions/admin/userLevel/userLevel.async.actions';
import { 
  setFilters,
  clearFilters,
  setCurrentPage 
} from '../../actions/admin/userLevel/userLevel.sync.actions';

const initialState = {
  userLevels: [],
  currentUserLevel: null,
  currentPage: 1,
  totalPages: 0,
  filters: {
    completed: '',
    locked: '',
    validWords: '',
    invalidWords: '',
    duplicateWords: '',
    wordsPerSecond: '',
    highestScore: '',
    mostValuableWordEntered: '',
    idLevel: '',
    idUser: ''
  },
  isLoading: false,
  error: null,
};

const userLevelSlice = createSlice({
  name: 'userLevel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetUserLevels.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncGetUserLevels.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;
        state.userLevels = content;
        state.currentPage = pageable.pageNumber + 1;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetUserLevels.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(asyncGetUserLevelById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncGetUserLevelById.fulfilled, (state, action) => {
        state.currentUserLevel = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncGetUserLevelById.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(asyncUpdateUserLevel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncUpdateUserLevel.fulfilled, (state, action) => {
        const index = state.userLevels.findIndex(
          (userLevel) => userLevel.id === action.payload.id
        );
        if (index !== -1) {
          state.userLevels[index] = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(asyncUpdateUserLevel.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(asyncDeleteUserLevel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncDeleteUserLevel.fulfilled, (state, action) => {
        state.userLevels = state.userLevels.filter(
          (userLevel) => userLevel.id !== action.payload
        );
        state.isLoading = false;
      })
      .addCase(asyncDeleteUserLevel.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = initialState.filters;
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
  },
});

export default userLevelSlice.reducer;
