import PropTypes from 'prop-types';

const UserLevelItem = ({ item, edit, deleteItem }) => {
  return (
    <tr>
      <td>{item.completed ? 'Yes' : 'No'}</td>
      <td>{item.locked ? 'Yes' : 'No'}</td>
      <td>{item.validWords}</td>
      <td>{item.invalidWords}</td>
      <td>{item.duplicateWords}</td>
      <td>{item.wordsPerSecond}</td>
      <td>{item.highestScore}</td>
      <td>{item.mostValuableWordEntered}</td>
      <td>{item.idLevel}</td>
      <td>{item.idUser}</td>
      <td>
        <button className="btn btn-info btn-sm me-2" onClick={() => edit(item.id)}>Edit</button>
        <button className="btn btn-danger btn-sm" onClick={() => deleteItem(item.id)}>Delete</button>
      </td>
    </tr>
  );
};

UserLevelItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    completed: PropTypes.bool.isRequired,
    locked: PropTypes.bool.isRequired,
    validWords: PropTypes.number,
    invalidWords: PropTypes.number,
    duplicateWords: PropTypes.number,
    wordsPerSecond: PropTypes.number,
    highestScore: PropTypes.number,
    mostValuableWordEntered: PropTypes.string,
    idLevel: PropTypes.number.isRequired,
    idUser: PropTypes.number.isRequired,
  }).isRequired,
  edit: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default UserLevelItem;
