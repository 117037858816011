import { createSlice } from '@reduxjs/toolkit';
import {
  asyncGetLevels,
  asyncCreateLevel,
  asyncUpdateLevel,
  asyncDeleteLevel,
} from '../../actions/admin/level/level.async.actions';
import {
  setFilters,
  clearFilters,
  setCurrentPage,
} from '../../actions/admin/level/level.sync.actions';

const levelAdminSlice = createSlice({
  name: 'level',
  initialState: {
    levels: [],
    currentPage: 1,
    totalPages: 0,
    filters: {
      isActive: '',
      requiredLetterFlag: '',
      isChill: '',
      stageId: '',
      languageId: '',
      checkCondition: '',
      requiredLetter: '',
      assignment: '',
      time: '',
      number: '',
      hint: '',
      requiredLetterPosition: '',
      numberOfWords: '',
      numberOfLetters: '',
      lettersAtLeast: '',
      endIsStart: '',
    },
    isLoading: false,
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // #region Async Get Levels
      .addCase(asyncGetLevels.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncGetLevels.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;
        state.levels = content;
        state.currentPage = pageable.pageNumber + 1;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetLevels.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Async Create Level
      .addCase(asyncCreateLevel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncCreateLevel.fulfilled, (state, action) => {
        state.levels.push(action.payload);
        state.isLoading = false;
        state.successMessage = 'Level created successfully';
      })
      .addCase(asyncCreateLevel.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Async Update Level
      .addCase(asyncUpdateLevel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncUpdateLevel.fulfilled, (state, action) => {
        const index = state.levels.findIndex((level) => level.id === action.payload.id);
        if (index !== -1) {
          state.levels[index] = action.payload;
        }
        state.isLoading = false;
        state.successMessage = 'Level updated successfully';
      })
      .addCase(asyncUpdateLevel.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Async Delete Level
      .addCase(asyncDeleteLevel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncDeleteLevel.fulfilled, (state, action) => {
        state.levels = state.levels.filter((level) => level.id !== action.payload.id);
        state.isLoading = false;
        state.successMessage = 'Level deleted successfully';
      })
      .addCase(asyncDeleteLevel.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Sync Actions
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = {
          isActive: '',
          requiredLetterFlag: '',
          isChill: '',
          stageId: '',
          languageId: '',
          checkCondition: '',
          requiredLetter: '',
          assignment: '',
          time: '',
          number: '',
          hint: '',
          requiredLetterPosition: '',
          numberOfWords: '',
          numberOfLetters: '',
          lettersAtLeast: '',
          endIsStart: '',
        };
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
    // #endregion
  },
});

export default levelAdminSlice.reducer;
