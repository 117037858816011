import { useContext } from 'react';
import { SessionContext } from '../context/SessionContext';

const useSessionContext = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useSessionContext debe ser usado dentro de un SessionProvider');
    }
    return context;
};

export default useSessionContext;
