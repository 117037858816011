import { createSlice } from "@reduxjs/toolkit";
import {
  asyncGetLetterGroups,
  asyncCreateLetterGroup,
  asyncUpdateLetterGroup,
  asyncDeleteLetterGroup,
} from "../../actions/admin/letterGroup/letterGroup.async.actions";
import {
  setFilters,
  clearFilters,
  setCurrentPage,
} from "../../actions/admin/letterGroup/letterGroup.sync.actions";

const letterGroupAdminSlice = createSlice({
  name: "letterGroup",
  initialState: {
    letterGroups: [],
    currentPage: 0,
    totalPages: 0,
    filters: {
      isActive: "",
      letters: "",
      idLanguage: "",
      idStage: "",
    },
    isLoading: false,
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetLetterGroups.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncGetLetterGroups.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;
        state.letterGroups = content;
        state.currentPage = pageable.pageNumber;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetLetterGroups.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncCreateLetterGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncCreateLetterGroup.fulfilled, (state, action) => {
        state.letterGroups.push(action.payload);
        state.isLoading = false;
        state.successMessage = "Letter group created successfully";
      })
      .addCase(asyncCreateLetterGroup.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncUpdateLetterGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncUpdateLetterGroup.fulfilled, (state, action) => {
        const index = state.letterGroups.findIndex(
          (group) => group.id === action.payload.id
        );
        if (index !== -1) {
          state.letterGroups[index] = action.payload;
        }
        state.isLoading = false;
        state.successMessage = "Letter group updated successfully";
      })
      .addCase(asyncUpdateLetterGroup.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncDeleteLetterGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncDeleteLetterGroup.fulfilled, (state, action) => {
        state.letterGroups = state.letterGroups.filter(
          (group) => group.id !== action.payload.id
        );
        state.isLoading = false;
        state.successMessage = "Letter group deleted successfully";
      })
      .addCase(asyncDeleteLetterGroup.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = { isActive: "", letters: "", idLanguage: "", idStage: "" };
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
  },
});

export default letterGroupAdminSlice.reducer;
