import apiAxiosWithCredentials from "../config/axios.config";
import { setWdmstToken, setWdmToken } from "../redux/reducers/common/user.reducer";

export const tokenMiddleware = (store) => (next) => async (action) => {
  const actionTypes = ['user/asyncLogin/fulfilled', 'user/asyncVerifyTwoFA/fulfilled'];

  if (!actionTypes.includes(action.type)) {
    return next(action);
  }

  const { accessToken, refreshToken } = action.payload || {};

  if (accessToken) {
    apiAxiosWithCredentials.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    store.dispatch(setWdmToken(accessToken));
  }

  if (refreshToken) {
    store.dispatch(setWdmstToken(refreshToken));
  }

  return next(action);
};
