import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/icon.png';

const PublicNavbar = () => {
  return (
    <Navbar
      expand="lg"
      fixed="top"
      style={{
        background: 'linear-gradient(135deg, #1e3c72, #2a5298)',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            alt="Wordamundo Logo"
            style={{
              width: '50px',
              height: '50px',
              objectFit: 'contain',
              borderRadius: '10px',
              transition: 'transform 0.3s ease',
            }}
            onMouseOver={(e) => (e.target.style.transform = 'scale(1.1)')}
            onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ borderColor: 'white' }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="/about-us"
              style={{
                color: 'white',
                marginRight: '20px',
                fontWeight: '500',
                transition: 'color 0.3s ease',
              }}
              onMouseOver={(e) => (e.target.style.color = '#ffdd59')}
              onMouseOut={(e) => (e.target.style.color = 'white')}
            >
              About us
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/terms-of-service"
              style={{
                color: 'white',
                marginRight: '20px',
                fontWeight: '500',
                transition: 'color 0.3s ease',
              }}
              onMouseOver={(e) => (e.target.style.color = '#ffdd59')}
              onMouseOut={(e) => (e.target.style.color = 'white')}
            >
              Terms of Service
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/privacy-policy"
              style={{
                color: 'white',
                fontWeight: '500',
                transition: 'color 0.3s ease',
              }}
              onMouseOver={(e) => (e.target.style.color = '#ffdd59')}
              onMouseOut={(e) => (e.target.style.color = 'white')}
            >
              Privacy Policy
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default PublicNavbar;
