import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { asyncUpdateLetterGroupLevel } from '../../redux/actions/admin/letterGroupLevel/letterGroupLevel.async.actions';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UpdateLetterGroupLevel = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { letterGroupLevels } = useSelector((state) => state.letterGroupLevelAdminSlice);

  const [formData, setFormData] = useState({
    used: false,
    idLetterGroup: '',
    idLevel: '',
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const letterGroupLevelToEdit = letterGroupLevels.find((item) => item.id === parseInt(id, 10));
    if (letterGroupLevelToEdit) {
      setFormData({
        used: letterGroupLevelToEdit.used,
        idLetterGroup: letterGroupLevelToEdit.idLetterGroup,
        idLevel: letterGroupLevelToEdit.idLevel,
      });
    }
  }, [id, letterGroupLevels]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      await dispatch(asyncUpdateLetterGroupLevel({ id, updatedLetterGroupLevel: formData })).unwrap();
      navigate('/argusma-words-panel/letter-group-level');
    } catch (err) {
      console.error('Failed to update Letter Group-Level:', err.message);
    }
  };

  const handleCancelUpdate = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="container mt-4">
      <h2>Edit Letter Group-Level</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="used" className="form-label">Is it Used?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="used"
            name="used"
            checked={formData.used}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="idLetterGroup" className="form-label">Letter Group ID</label>
          <input
            type="number"
            className="form-control"
            id="idLetterGroup"
            name="idLetterGroup"
            value={formData.idLetterGroup}
            readOnly
          />
        </div>
        <div className="mb-3">
          <label htmlFor="idLevel" className="form-label">Level ID</label>
          <input
            type="number"
            className="form-control"
            id="idLevel"
            name="idLevel"
            value={formData.idLevel}
            readOnly
          />
        </div>

        <button type="submit" className="btn btn-primary">Update</button>
      </form>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleConfirmUpdate}
        onCancel={handleCancelUpdate}
      />
    </div>
  );
};

export default UpdateLetterGroupLevel;
