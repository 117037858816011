import PropTypes from 'prop-types';
import UserLevelItem from './UserLevelItem';

const UserLevelList = ({ list, edit, deleteItem }) => {
  return (
    <>
      {list.map((itm) => (
        <UserLevelItem 
          key={itm.id} 
          item={itm} 
          edit={edit} 
          deleteItem={deleteItem} 
        />
      ))}
    </>
  );
};

UserLevelList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      completed: PropTypes.bool.isRequired,
      locked: PropTypes.bool.isRequired,
      validWords: PropTypes.number,
      invalidWords: PropTypes.number,
      duplicateWords: PropTypes.number,
      wordsPerSecond: PropTypes.number,
      highestScore: PropTypes.number,
      mostValuableWordEntered: PropTypes.string,
      idLevel: PropTypes.number.isRequired,
      idUser: PropTypes.number.isRequired,
    })
  ).isRequired,
  edit: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default UserLevelList;
