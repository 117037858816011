import { createAsyncThunk } from "@reduxjs/toolkit";
import apiAxiosWithCredentials from "../../../config/axios.config";

export const asyncLogin = createAsyncThunk(
  "user/asyncLogin",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.post("/auth/login", {
        username,
        password,
      });

      const { accessToken, refreshToken } = response.data;

      apiAxiosWithCredentials.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      return { ...response.data, accessToken, refreshToken };
    } catch (error) {
      console.error(error);
      
      return rejectWithValue('User or password not correct'); 
    }
  }
);


export const asyncVerifyTwoFA = createAsyncThunk(
  "user/asyncVerifyTwoFA",
  async ({ otp, username }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.post("/auth/verify-otp", {
        otp,
        username,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
