import { createAsyncThunk } from '@reduxjs/toolkit';
import apiAxiosWithCredentials from '../../../../config/axios.config';

export const asyncGetLetterGroupWords = createAsyncThunk(
  'letterGroupWord/asyncGetLetterGroupWords',
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/letter-group-words/cms/search/?page=${currentPage - 1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncGetLetterGroupWordById = createAsyncThunk(
  'letterGroupWord/asyncGetLetterGroupWordById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.get(`/arguscreative/wordamundo/letter-group-words/cms/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);


export const asyncUpdateLetterGroupWord = createAsyncThunk(
  'letterGroupWord/asyncUpdateLetterGroupWord',
  async ({ id, ...updatedLetterGroupWord }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(`/arguscreative/wordamundo/letter-group-words/cms/${id}`, updatedLetterGroupWord);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncDeleteLetterGroupWord = createAsyncThunk(
  'letterGroupWord/asyncDeleteLetterGroupWord',
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.delete(`/arguscreative/wordamundo/letter-group-words/cms/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
