import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { logout } from '../../redux/reducers/common/user.reducer';

const AdminNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { wdmToken } = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar bg="light" expand="lg" className="shadow-sm mb-4">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <span className="text-primary">Wordamundo</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu} />
        <Navbar.Collapse id="basic-navbar-nav" className={isOpen ? 'show' : ''}>
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/argusma-words-panel/level">Levels</Nav.Link>
            <Nav.Link as={Link} to="/argusma-words-panel/user">Users</Nav.Link>
            <Nav.Link as={Link} to="/argusma-words-panel/language">Languages</Nav.Link>
            <Nav.Link as={Link} to="/argusma-words-panel/word">Words</Nav.Link>
            <Nav.Link as={Link} to="/argusma-words-panel/stage">Stages</Nav.Link>
            <Nav.Link as={Link} to="/argusma-words-panel/letter-group">Letter Groups</Nav.Link>
            <NavDropdown title="Manage link tables" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/argusma-words-panel/letter-group-level">Letter Group Levels</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/argusma-words-panel/letter-group-word">Letter Group Words</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/argusma-words-panel/user-level">User levels</NavDropdown.Item>

            </NavDropdown>
          </Nav>
          {wdmToken ? (
            <Nav>
              <Nav.Link onClick={handleLogout} className="text-danger">
                Logout
              </Nav.Link>
            </Nav>
          ) : (
            <Nav>
              <Nav.Link as={Link} to="/argusma-words-panel/auth/login">Login</Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
