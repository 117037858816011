import LetterGroupLevelItem from './LetterGroupLevelItem';
import PropTypes from 'prop-types';

const LetterGroupLevelList = ({ list, edit, confirmDelete }) => {
  return (
    <>
      {list.map((item) => (
        <LetterGroupLevelItem
          key={item.id}
          item={item}
          edit={edit}
          confirmDelete={confirmDelete}
        />
      ))}
    </>
  );
};

LetterGroupLevelList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      used: PropTypes.bool.isRequired,
      idLetterGroup: PropTypes.number.isRequired,
      idLevel: PropTypes.number.isRequired,
    })
  ).isRequired,
  edit: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default LetterGroupLevelList;
