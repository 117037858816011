import { createSlice } from "@reduxjs/toolkit";
import {
  asyncGetLetterGroupLevels,
  asyncUpdateLetterGroupLevel,
  asyncDeleteLetterGroupLevel,
} from "../../actions/admin/letterGroupLevel/letterGroupLevel.async.actions";
import {
  setFilters,
  clearFilters,
  setCurrentPage,
} from "../../actions/admin/letterGroupLevel/letterGroupLevel.sync.actions";

const letterGroupLevelAdminSlice = createSlice({
  name: "letterGroupLevel",
  initialState: {
    letterGroupLevels: [],
    currentPage: 0,
    totalPages: 0,
    filters: {
      used: "",
      idLetterGroup: "",
      idLevel: "",
    },
    isLoading: false,
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetLetterGroupLevels.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncGetLetterGroupLevels.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;
        state.letterGroupLevels = content;
        state.currentPage = pageable.pageNumber;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetLetterGroupLevels.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(asyncUpdateLetterGroupLevel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncUpdateLetterGroupLevel.fulfilled, (state, action) => {
        const index = state.letterGroupLevels.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.letterGroupLevels[index] = action.payload;
        }
        state.isLoading = false;
        state.successMessage = "Letter Group-Level updated successfully";
      })
      .addCase(asyncUpdateLetterGroupLevel.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(asyncDeleteLetterGroupLevel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncDeleteLetterGroupLevel.fulfilled, (state, action) => {
        state.letterGroupLevels = state.letterGroupLevels.filter(
          (item) => item.id !== action.payload.id
        );
        state.isLoading = false;
        state.successMessage = "Letter Group-Level deleted successfully";
      })
      .addCase(asyncDeleteLetterGroupLevel.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = { used: "", idLetterGroup: "", idLevel: "" };
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
  },
});

export default letterGroupLevelAdminSlice.reducer;
