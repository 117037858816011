import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncCreateLanguage } from '../../redux/actions/admin/language/language.async.actions';

const NewLanguage = () => {
  const [formData, setFormData] = useState({
    language: '',
    isActive: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.language.trim()) {
      setError('Language name is required');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await dispatch(asyncCreateLanguage(formData)).unwrap();
      navigate('/argusma-words-panel/language');
    } catch (err) {
      setError('Failed to add language. Please try again.', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Add New Language</h2>
      
      {error && <p className="text-danger">{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="language" className="form-label">Language</label>
          <input
            required
            type="text"
            className="form-control"
            id="language"
            name="language"
            value={formData.language}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="isActive" className="form-label">Is Active?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="isActive"
            name="isActive"
            checked={formData.isActive}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
          {isLoading ? 'Adding...' : 'Add Language'}
        </button>
      </form>
    </div>
  );
};

export default NewLanguage;
