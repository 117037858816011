import React from 'react';

const LetterGroupWordItem = ({ item, edit }) => {
    return (
        <tr>
            <td>{item.idLetterGroup}</td>
            <td>{item.idWord}</td>
            <td>
                <button onClick={() => edit(item.id)}>Edit</button>
            </td>
        </tr>
    );
};

export default LetterGroupWordItem;
