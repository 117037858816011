import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { asyncGetLanguages, asyncUpdateLanguage } from '../../redux/actions/admin/language/language.async.actions';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UpdateLanguage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { languages, isLoading, error } = useSelector((state) => state.languageAdminSlice);

    const [formData, setFormData] = useState({
        isActive: false,
        language: ''
    });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    useEffect(() => {
        if (id && !languages.length) {
            dispatch(asyncGetLanguages({ currentPage: 1, filters: {} }));
        } else if (id && languages.length) {
            const languageToEdit = languages.find((lang) => lang.id === parseInt(id, 10));
            if (languageToEdit) {
                setFormData({
                    isActive: languageToEdit.isActive,
                    language: languageToEdit.language
                });
            }
        }
    }, [dispatch, id, languages]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.language.trim()) {
            setUpdateError('Language name is required.');
            return;
        }
        setShowConfirmation(true);
    };

    const handleConfirmUpdate = async () => {
        setIsUpdating(true);
        setUpdateError(null);
        try {
            await dispatch(asyncUpdateLanguage({ id, updatedLanguage: formData })).unwrap();
            setShowConfirmation(false);
            navigate('/argusma-words-panel/language');
        } catch (err) {
            setUpdateError('Failed to update language. Please try again.', err.message);
        } finally {
            setIsUpdating(false);
        }
    };

    const handleCancelUpdate = () => {
        setShowConfirmation(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading language: {error}</div>;
    }

    return (
        <div className="container mt-4">
            <h2>Edit Language</h2>

            {updateError && <p className="text-danger">{updateError}</p>}

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="isActive" className="form-label">Is it active?</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="isActive"
                        name="isActive"
                        checked={formData.isActive}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="language" className="form-label">Language</label>
                    <input
                        required
                        type="text"
                        className="form-control"
                        id="language"
                        name="language"
                        value={formData.language}
                        onChange={handleChange}
                    />
                </div>

                <button type="submit" className="btn btn-primary" disabled={isUpdating}>
                    {isUpdating ? 'Updating...' : 'Update'}
                </button>
            </form>

            <ConfirmationPopup
                isOpen={showConfirmation}
                onConfirm={handleConfirmUpdate}
                onCancel={handleCancelUpdate}
            />
        </div>
    );
};

export default UpdateLanguage;
