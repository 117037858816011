import { createSlice } from "@reduxjs/toolkit";
import {
  asyncGetStages,
  asyncCreateStage,
  asyncUpdateStage,
  asyncDeleteStage,
} from "../../actions/admin/stage/stage.async.actions";
import { setFilters, clearFilters, setCurrentPage } from "../../actions/admin/stage/stage.sync.actions";

const stageAdminSlice = createSlice({
  name: "stage",
  initialState: {
    stages: [],
    currentPage: 0,
    totalPages: 0,
    filters: {
      isActive: "",
      letters: "",
      number: "",
    },
    isLoading: false,
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetStages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncGetStages.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;
        state.stages = content;
        state.currentPage = pageable.pageNumber;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetStages.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncCreateStage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncCreateStage.fulfilled, (state, action) => {
        state.stages.push(action.payload);
        state.isLoading = false;
        state.successMessage = "Stage created successfully";
      })
      .addCase(asyncCreateStage.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncUpdateStage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncUpdateStage.fulfilled, (state, action) => {
        const index = state.stages.findIndex(
          (stage) => stage.id === action.payload.id
        );
        if (index !== -1) {
          state.stages[index] = action.payload;
        }
        state.isLoading = false;
        state.successMessage = "Stage updated successfully";
      })
      .addCase(asyncUpdateStage.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncDeleteStage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncDeleteStage.fulfilled, (state, action) => {
        state.stages = state.stages.filter(
          (stage) => stage.id !== action.payload.id
        );
        state.isLoading = false;
        state.successMessage = "Stage deleted successfully";
      })
      .addCase(asyncDeleteStage.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = { isActive: "", letters: "", number: "" };
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
  },
});

export default stageAdminSlice.reducer;
