import PropTypes from 'prop-types';
import LanguageItem from './LanguageItem';

const LanguageList = ({ list, editLanguage, confirmDeleteLanguage }) => {
  return (
    <table className="table table-striped table-bordered custom-table">
      <thead className="thead-dark">
        <tr>
          <th>Is Active?</th>
          <th>Language</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <LanguageItem
            key={item.id}
            item={item}
            editLanguage={editLanguage}
            confirmDeleteLanguage={confirmDeleteLanguage}
          />
        ))}
      </tbody>
    </table>
  );
};

LanguageList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      language: PropTypes.string.isRequired,
    })
  ).isRequired,
  editLanguage: PropTypes.func.isRequired,
  confirmDeleteLanguage: PropTypes.func.isRequired,
};

export default LanguageList;
