import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncGetLanguages, asyncDeleteLanguage } from '../../redux/actions/admin/language/language.async.actions';
import { setFilters, clearFilters, setCurrentPage } from '../../redux/actions/admin/language/language.sync.actions';
import LanguageList from './LanguageList';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const LanguageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { languages, currentPage, totalPages, filters, isLoading, error, successMessage } = useSelector((state) => state.languageAdminSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [languageToDelete, setLanguageToDelete] = useState(null);

  useEffect(() => {
    dispatch(asyncGetLanguages({ currentPage, filters }));
  }, [dispatch, currentPage, filters]);

  const handleFilterChange = (filter, value) => {
    dispatch(setFilters({ ...filters, [filter]: value }));
    dispatch(setCurrentPage(1));
  };

  const clearAllFilters = () => {
    dispatch(clearFilters());
    dispatch(setCurrentPage(1));
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const editLanguage = (id) => {
    navigate(`/argusma-words-panel/language/update/${id}`);
  };

  const confirmDeleteLanguage = (id) => {
    setLanguageToDelete(id);
    setShowConfirmation(true);
  };

  const handleDeleteLanguage = () => {
    if (languageToDelete) {
      dispatch(asyncDeleteLanguage(languageToDelete))
        .then(() => {
          dispatch(asyncGetLanguages({ currentPage, filters }))
          setShowConfirmation(false);
          setLanguageToDelete(null);
        });
    }
  };

  const cancelDeleteLanguage = () => {
    setShowConfirmation(false);
    setLanguageToDelete(null);
  };

  return (
    <div className="container mt-4">
      <h2>Languages</h2>

      {isLoading && <p>Loading...</p>}
      {error && <p className="text-danger">Error: {error}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}

      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
        <button className="btn btn-primary" onClick={() => navigate('/argusma-words-panel/language/add')}>Add New</button>
      </div>

      <div className="mb-3">
        <label htmlFor="filterIsActive" className="form-label">Filter by Active Status</label>
        <select
          id="filterIsActive"
          className="form-select"
          value={filters.isActive}
          onChange={(e) => handleFilterChange('isActive', e.target.value)}
        >
          <option value="">All</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="filterLanguage" className="form-label">Filter by Language</label>
        <input
          type="text"
          id="filterLanguage"
          className="form-control"
          value={filters.language}
          onChange={(e) => handleFilterChange('language', e.target.value)}
          placeholder="Enter language"
        />
      </div>

      <LanguageList
        list={languages}
        editLanguage={editLanguage}
        confirmDeleteLanguage={confirmDeleteLanguage}
      />

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1 || isLoading}>Prev</button>
        <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages || isLoading}>Next</button>
      </div>
      <div className="mt-3">
        Page {currentPage} of {totalPages}
      </div>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleDeleteLanguage}
        onCancel={cancelDeleteLanguage}
      />
    </div>
  );
};

export default LanguageContainer;
