import { createSlice } from '@reduxjs/toolkit';
import {
  asyncGetUsers,
  asyncUpdateUser,
  asyncDeleteUser,
} from '../../actions/admin/user/user.async.actions';
import {
  setFilters,
  clearFilters,
  setCurrentPage,
} from '../../actions/admin/user/user.sync.actions';

const userAdminSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    currentPage: 1,
    totalPages: 0,
    filters: {
      isActive: '',
      email: '',
      username: '',
      avatar: '',
      role: '',
      diamonds: '',
      coins: '',
      validWords: '',
      invalidWords: '',
      duplicateWords: '',
      firstLoad: '',
      appleId: '',
      googleId: '',
      idLanguage: '',
      userRole: '',
      coinCount: '',
      validWordCount: '',
      invalidWordCount: '',
      duplicateWordCount: '',
      isTwoFA: '',
      maxLevel: '',
      totalScore: '',
      previousUsername: '',
      createdAt: '',
      updatedAt: '',
      deletedAt: '',
    },
    isLoading: false,
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // #region Async Get Users
      .addCase(asyncGetUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncGetUsers.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;
        state.users = content.map(user => ({
          ...user,
          isActive: user.isActive ?? false,
          email: user.email ?? '',
          username: user.username ?? '',
          avatar: user.avatar ?? '',
          role: user.role ?? '',
          diamonds: user.diamonds ?? 0,
          coins: user.coins ?? 0,
          validWords: user.validWords ?? 0,
          invalidWords: user.invalidWords ?? 0,
          duplicateWords: user.duplicateWords ?? 0,
          firstLoad: user.firstLoad ?? false,
          appleId: user.appleId ?? '',
          googleId: user.googleId ?? '',
          idLanguage: user.idLanguage ?? 0,
          userRole: user.userRole ?? '',
          coinCount: user.coinCount ?? 0,
          validWordCount: user.validWordCount ?? 0,
          invalidWordCount: user.invalidWordCount ?? 0,
          duplicateWordCount: user.duplicateWordCount ?? 0,
          isTwoFA: user.isTwoFA ?? false,
          maxLevel: user.maxLevel ?? 0,
          totalScore: user.totalScore ?? 0,
          previousUsername: user.previousUsername ?? '',
          createdAt: user.createdAt ?? null,
          updatedAt: user.updatedAt ?? null,
          deletedAt: user.deletedAt ?? null,
        }));
        state.currentPage = pageable.pageNumber + 1;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetUsers.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Async Update User
      .addCase(asyncUpdateUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncUpdateUser.fulfilled, (state, action) => {
        const index = state.users.findIndex((user) => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index] = action.payload;
        }
        state.isLoading = false;
        state.successMessage = 'User updated successfully';
      })
      .addCase(asyncUpdateUser.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Async Delete User
      .addCase(asyncDeleteUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncDeleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.payload.id);
        state.isLoading = false;
        state.successMessage = 'User deleted successfully';
      })
      .addCase(asyncDeleteUser.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Sync Actions
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = {
          isActive: '',
          email: '',
          username: '',
          avatar: '',
          role: '',
          diamonds: '',
          coins: '',
          validWords: '',
          invalidWords: '',
          duplicateWords: '',
          firstLoad: '',
          appleId: '',
          googleId: '',
          idLanguage: '',
          userRole: '',
          coinCount: '',
          validWordCount: '',
          invalidWordCount: '',
          duplicateWordCount: '',
          isTwoFA: '',
          maxLevel: '',
          totalScore: '',
          previousUsername: '',
          createdAt: '',
          updatedAt: '',
          deletedAt: '',
        };
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
    // #endregion
  },
});

export default userAdminSlice.reducer;
