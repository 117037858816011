import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { asyncGetLetterGroups, asyncUpdateLetterGroup } from '../../redux/actions/admin/letterGroup/letterGroup.async.actions';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UpdateLetterGroup = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { letterGroups } = useSelector((state) => state.letterGroupAdminSlice);
  const [formData, setFormData] = useState({
    isActive: false,
    letters: '',
    languageId: '',
    stageId: ''
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (!letterGroups.length) {
      dispatch(asyncGetLetterGroups({ currentPage: 1, filters: {} }));
    } else {
      const letterGroup = letterGroups.find((group) => group.id === parseInt(id, 10));
      if (letterGroup) {
        setFormData({
          isActive: letterGroup.isActive,
          letters: letterGroup.letters,
          languageId: letterGroup.languageId,
          stageId: letterGroup.stageId
        });
      }
    }
  }, [dispatch, id, letterGroups]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      await dispatch(asyncUpdateLetterGroup({ id, updatedLetterGroup: formData })).unwrap();
      navigate('/argusma-words-panel/letter-group');
    } catch (err) {
      console.error('Failed to update letter group:', err);
    }
  };

  const handleCancelUpdate = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="container mt-4">
      <h2>Edit Letter Group</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="isActive" className="form-label">Is it active?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="isActive"
            name="isActive"
            checked={formData.isActive}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="letters" className="form-label">Letters</label>
          <input required type="text" className="form-control" id="letters" name="letters" value={formData.letters} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="languageId" className="form-label">Language ID</label>
          <input required type="number" className="form-control" id="languageId" name="languageId" value={formData.languageId} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="stageId" className="form-label">Stage ID</label>
          <input required type="number" className="form-control" id="stageId" name="stageId" value={formData.stageId} onChange={handleChange} />
        </div>
        <button type="submit" className="btn btn-primary">Update</button>
      </form>
      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleConfirmUpdate}
        onCancel={handleCancelUpdate}
      />
    </div>
  );
};

export default UpdateLetterGroup;
