import { combineReducers, configureStore } from "@reduxjs/toolkit"
import languageReducer from "./admin/language.reducer"
import userReducer from "./common/user.reducer"
import letterGroupReducer from "./admin/letterGroup.reducer"
import stageReducer from "./admin/stage.reducer"
import wordReducer from "./admin/word.reducer"
import letterGroupLevelReducer from "./admin/letterGroupLevel.reducer"
import userAdminReducer from "./admin/user.reducer"
import levelReducer from "./admin/level.reducer"
import letterGroupWordReducer from "./admin/letterGroupWord.reducer"
import userLevelReducer from "./admin/userLevel.reducer"
import { tokenMiddleware } from "../../middleware/token.middleware";

const rootReducer = combineReducers({
languageAdminSlice : languageReducer,
userSlice: userReducer,
letterGroupAdminSlice: letterGroupReducer,
stageAdminSlice: stageReducer,
wordAdminSlice: wordReducer,
letterGroupLevelAdminSlice: letterGroupLevelReducer,
userAdminSlice: userAdminReducer,
levelAdminSlice: levelReducer,
letterGroupWordAdminSlice: letterGroupWordReducer,
userLevelAdminSlice: userLevelReducer,

})


const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(tokenMiddleware),
});

export default store;
