export const validateEnv = () => {
    const requiredEnvs = [
        "VITE_API_URL",
        "VITE_FAF_TESTERS_FORM"
    ]

    requiredEnvs.forEach((env)=> {
        if(!import.meta.env[env]){
            throw new Error(`The environment variable ${env} is not set`)
        }
    })
}

export const API_URL = import.meta.env.VITE_API_URL
export const FAF_TESTER_FORM = import.meta.env.VITE_FAF_TESTERS_FORM