import PropTypes from 'prop-types';
import './ConfirmationPopup.css';

const ConfirmationPopup = ({ isOpen, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="popup-container">
            <div className="popup">
                <p>Are you sure?</p>
                <button className="btn btn-danger" onClick={onConfirm}>Yes</button>
                <button className="btn btn-secondary" onClick={onCancel}>No</button>
            </div>
        </div>
    );
}

ConfirmationPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConfirmationPopup;
