import PropTypes from 'prop-types';
import LevelItem from './LevelItem';

const LevelList = ({ list, editLevel, confirmDeleteLevel }) => {
  return (
    <table className="table table-striped table-bordered custom-table">
      <thead className="thead-dark">
        <tr>
          <th>Is Active?</th>
          <th>Number</th>
          <th>Hint</th>
          <th>Assignment</th>
          <th>Time</th>
          <th>Required Letter?</th>
          <th>Required Letter Position</th>
          <th>Check Condition</th>
          <th>Is Chill?</th>
          <th>Stage ID</th>
          <th>Language ID</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <LevelItem
            key={item.id}
            item={item}
            editLevel={editLevel}
            confirmDeleteLevel={confirmDeleteLevel}
          />
        ))}
      </tbody>
    </table>
  );
};

LevelList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      hint: PropTypes.string,
      assignment: PropTypes.string.isRequired,
      time: PropTypes.number.isRequired,
      requiredLetterFlag: PropTypes.bool,
      requiredLetter: PropTypes.string,
      checkCondition: PropTypes.string,
      isChill: PropTypes.bool,
      stage: PropTypes.number.isRequired,
      language: PropTypes.string.isRequired,
    })
  ).isRequired,
  editLevel: PropTypes.func.isRequired,
  confirmDeleteLevel: PropTypes.func.isRequired,
};

export default LevelList;
