import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer
      style={{
        background: 'linear-gradient(135deg, #2a5298, #1e3c72)',
        color: 'white',
        padding: '30px 20px',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
      <p
        style={{
          margin: '0 0 10px',
          fontSize: '1.2rem',
          fontWeight: '500',
        }}
      >
        &copy; {new Date().getFullYear()} Wordamundo. All rights reserved.
      </p>
      <p style={{ margin: '0', fontSize: '1rem' }}>
        <Link
          to="/terms-of-service"
          style={{
            color: '#ffdd59',
            textDecoration: 'none',
            marginRight: '15px',
          }}
          onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
          onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
        >
          Terms of Service
        </Link>
        |
        <Link
          to="/privacy-policy"
          style={{
            color: '#ffdd59',
            textDecoration: 'none',
            marginLeft: '15px',
          }}
          onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
          onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
        >
          Privacy Policy
        </Link>
      </p>
      <div style={{ marginTop: '20px' }}>
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.color = '#ffdd59')}
          onMouseOut={(e) => (e.target.style.color = 'white')}
        >
          <i className="fab fa-facebook"></i>
        </a>
        <a
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.color = '#ffdd59')}
          onMouseOut={(e) => (e.target.style.color = 'white')}
        >
          <i className="fab fa-twitter"></i>
        </a>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.color = '#ffdd59')}
          onMouseOut={(e) => (e.target.style.color = 'white')}
        >
          <i className="fab fa-instagram"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
