import { createAsyncThunk } from "@reduxjs/toolkit";
import apiAxiosWithCredentials from "../../../../config/axios.config";

export const asyncGetWords = createAsyncThunk(
  "word/asyncGetWords",
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/words/cms/search/?page=${currentPage - 1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(
            value
          )}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const asyncCreateWord = createAsyncThunk(
  "word/asyncCreateWord",
  async (newWord, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.post("/arguscreative/wordamundo/words/cms", {
        letters: newWord.letters,
        languageId: newWord.idLanguage,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const asyncUpdateWord = createAsyncThunk(
  "word/asyncUpdateWord",
  async ({ id, updatedWord }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(`/arguscreative/wordamundo/words/cms/${id}`, {
        letters: updatedWord.letters,
        languageId: updatedWord.idLanguage,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const asyncDeleteWord = createAsyncThunk(
  "word/asyncDeleteWord",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.delete(`/arguscreative/wordamundo/words/cms/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
