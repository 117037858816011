import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  asyncGetUserLevels, 
  asyncDeleteUserLevel 
} from '../../redux/actions/admin/userLevel/userLevel.async.actions';
import { 
  setFilters, 
  clearFilters, 
  setCurrentPage 
} from '../../redux/actions/admin/userLevel/userLevel.sync.actions';
import UserLevelList from './UserLevelList';

const UserLevelContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userLevels, currentPage, totalPages, filters, isLoading } = useSelector(
    (state) => state.userLevelAdminSlice
  );

  useEffect(() => {
    dispatch(asyncGetUserLevels({ currentPage, filters }));
  }, [dispatch, currentPage, filters]);

  const handleFilterChange = (filter, value) => {
    const updatedFilters = { ...filters, [filter]: value };
    dispatch(setFilters(updatedFilters));
    dispatch(setCurrentPage(1));
    dispatch(asyncGetUserLevels({ currentPage: 1, filters: updatedFilters }));
  };

  const clearAllFilters = () => {
    dispatch(clearFilters());
    dispatch(setCurrentPage(1));
    dispatch(asyncGetUserLevels({ currentPage: 1, filters: {} }));
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const editUserLevel = (id) => {
    navigate(`/user-level/update/${id}`);
  };

  const deleteUserLevel = (id) => {
    dispatch(asyncDeleteUserLevel(id))
      .then(() => dispatch(asyncGetUserLevels({ currentPage, filters })))
      .catch((error) => console.error('Failed to delete user level:', error));
  };

  return (
    <div className="container mt-4">
      <h2>User-Level</h2>

      <div className="mb-3">
        <div className="d-flex flex-wrap gap-2">
          <input
            type="text"
            placeholder="Valid Words"
            className="form-control form-control-sm"
            value={filters.validWords || ''}
            onChange={(e) => handleFilterChange('validWords', e.target.value)}
          />
          <input
            type="text"
            placeholder="Invalid Words"
            className="form-control form-control-sm"
            value={filters.invalidWords || ''}
            onChange={(e) => handleFilterChange('invalidWords', e.target.value)}
          />
          <input
            type="text"
            placeholder="Highest Score"
            className="form-control form-control-sm"
            value={filters.highestScore || ''}
            onChange={(e) => handleFilterChange('highestScore', e.target.value)}
          />
          <select
            className="form-select form-select-sm"
            value={filters.completed || ''}
            onChange={(e) => handleFilterChange('completed', e.target.value)}
          >
            <option value="">All</option>
            <option value="true">Completed</option>
            <option value="false">Not Completed</option>
          </select>
          <select
            className="form-select form-select-sm"
            value={filters.locked || ''}
            onChange={(e) => handleFilterChange('locked', e.target.value)}
          >
            <option value="">All</option>
            <option value="true">Locked</option>
            <option value="false">Unlocked</option>
          </select>
        </div>
        <button className="btn btn-secondary mt-2" onClick={clearAllFilters}>
          Clear All Filters
        </button>
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <table className="table table-striped table-bordered custom-table">
          <thead className="thead-dark">
            <tr>
              <th>Is Complete?</th>
              <th>Is Locked?</th>
              <th>Valid Words</th>
              <th>Invalid Words</th>
              <th>Duplicate Words</th>
              <th>Words per Second</th>
              <th>Highest Score</th>
              <th>Most Valuable Word Entered</th>
              <th>Level ID</th>
              <th>User ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <UserLevelList 
              list={userLevels} 
              edit={editUserLevel} 
              deleteItem={deleteUserLevel}
            />
          </tbody>
        </table>
      )}

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1 || isLoading}>
          Prev
        </button>
        <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages || isLoading}>
          Next
        </button>
      </div>
      <div className="mt-3">
        Page {currentPage} of {totalPages}
      </div>
    </div>
  );
};

export default UserLevelContainer;
