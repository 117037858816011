import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { asyncUpdateLetterGroupWord, asyncGetLetterGroupWordById } from '../../redux/actions/admin/letterGroupWord/letterGroupWord.async.actions';

const UpdateLetterGroupWord = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        idLetterGroup: '',
        idWord: ''
    });

    useEffect(() => {
        if (id) {
            dispatch(asyncGetLetterGroupWordById(id))
                .unwrap()
                .then((data) => setFormData(data))
                .catch((error) => console.error('Error fetching letter-group-word:', error));
        }
    }, [dispatch, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(asyncUpdateLetterGroupWord({ id, ...formData }))
            .unwrap()
            .then(() => navigate('/argusma-words-panel/letter-group-word'))
            .catch((error) => console.error('Error updating letter-group-word:', error));
    };

    return (
        <div className="container mt-4">
            <h2>Edit Letter Group-Word</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="idLetterGroup" className="form-label">Letter Group ID</label>
                    <input
                        type="number"
                        className="form-control"
                        id="idLetterGroup"
                        name="idLetterGroup"
                        value={formData.idLetterGroup}
                        onChange={handleChange}
                        readOnly
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="idWord" className="form-label">Word ID</label>
                    <input
                        type="number"
                        className="form-control"
                        id="idWord"
                        name="idWord"
                        value={formData.idWord}
                        onChange={handleChange}
                        readOnly
                    />
                </div>
                <button type="submit" className="btn btn-primary">Update</button>
            </form>
        </div>
    );
};

export default UpdateLetterGroupWord;
