import { createSlice } from "@reduxjs/toolkit";
import {
  asyncGetWords,
  asyncCreateWord,
  asyncUpdateWord,
  asyncDeleteWord,
} from "../../actions/admin/word/word.async.actions";
import { setFilters, clearFilters, setCurrentPage } from "../../actions/admin/word/word.sync.actions";

const wordAdminSlice = createSlice({
  name: "word",
  initialState: {
    words: [],
    currentPage: 0,
    totalPages: 0,
    filters: {
      isActive: "",
      letters: "",
      idLanguage: "",
    },
    isLoading: false,
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetWords.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncGetWords.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;
        state.words = content;
        state.currentPage = pageable.pageNumber;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetWords.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncCreateWord.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncCreateWord.fulfilled, (state, action) => {
        state.words.push(action.payload);
        state.isLoading = false;
        state.successMessage = "Word created successfully";
      })
      .addCase(asyncCreateWord.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncUpdateWord.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncUpdateWord.fulfilled, (state, action) => {
        const index = state.words.findIndex(
          (word) => word.id === action.payload.id
        );
        if (index !== -1) {
          state.words[index] = action.payload;
        }
        state.isLoading = false;
        state.successMessage = "Word updated successfully";
      })
      .addCase(asyncUpdateWord.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncDeleteWord.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncDeleteWord.fulfilled, (state, action) => {
        state.words = state.words.filter(
          (word) => word.id !== action.payload.id
        );
        state.isLoading = false;
        state.successMessage = "Word deleted successfully";
      })
      .addCase(asyncDeleteWord.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = { isActive: "", letters: "", idLanguage: "" };
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
  },
});

export default wordAdminSlice.reducer;
