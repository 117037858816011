import StageItem from './StageItem';
import PropTypes from 'prop-types';

const StageList = ({ list, edit, confirmDelete }) => {
  return (
    <>
      {list.map((item) => (
        <StageItem
          key={item.id}
          item={item}
          edit={edit}
          confirmDelete={confirmDelete}
        />
      ))}
    </>
  );
};

StageList.propTypes = {
  list: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default StageList;
