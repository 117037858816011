import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import AdminPanel from "../pages/AdminPanel";
import AdminLogin from "../pages/AdminLogin";
import QrCode from "../pages/QrCode";
import Home from "../pages/Home";
import AdminNavbar from "../components/adminNavbar/AdminNavbar";
import LevelContainer from '../components/level/LevelContainer';
import NewLevel from '../components/level/NewLevel';
import UpdateLevel from '../components/level/UpdateLevel';
import UserContainer from '../components/user/UserContainer';
import UpdateUser from '../components/user/UpdateUser';
import UserLevelContainer from '../components/userLevel/UserLevelContainer';
import UpdateUserLevel from '../components/userLevel/UserLevelUpdate';
import LanguageContainer from '../components/language/LanguageContainer';
import NewLanguage from '../components/language/NewLanguage';
import UpdateLanguage from '../components/language/UpdateLanguage';
import LetterGroupContainer from '../components/letterGroup/LetterGroupContainer';
import NewLetterGroup from '../components/letterGroup/NewLetterGroup';
import UpdateLetterGroup from '../components/letterGroup/UpdateLetterGroup';
import LetterGroupLevelContainer from '../components/letterGroupLevel/LetterGroupLevelContainer';
import UpdateLetterGroupLevel from '../components/letterGroupLevel/LetterGroupLevelUpdate';
import LetterGroupWordContainer from '../components/letterGroupWord/LetterGroupWordContainer';
import UpdateLetterGroupWord from '../components/letterGroupWord/LetterGroupWordUpdate';
import StageContainer from '../components/stage/StageContainer';
import NewStage from '../components/stage/NewStage';
import UpdateStage from '../components/stage/UpdateStage';
import WordContainer from '../components/word/WordContainer';
import NewWord from '../components/word/NewWord';
import UpdateWord from '../components/word/UpdateWord';
import NotFound from '../components/notfound/NotFound';
import TermsOfService from '../pages/TermsOfService';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import AboutUs from '../pages/AboutUs';

const RoutesContainer = () => {
    const { wdmToken, isTwoFaEnabled, is2FaVerified } = useSelector((state) => state.userSlice);

    const showNavbar = wdmToken && isTwoFaEnabled && is2FaVerified;

    return (
        <>
            {showNavbar && <AdminNavbar />}

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/argusma-words-panel/auth/login" element={<AdminLogin />} />
                <Route path='*' element={<NotFound />} />

                {isTwoFaEnabled && !is2FaVerified && (
                    <>
                        <Route path="/argusma-words-panel/auth/set-qr-code" element={<QrCode />} />
                        <Route path="*" element={<Navigate to="/argusma-words-panel/auth/set-qr-code" />} />
                    </>
                )}


                {wdmToken && isTwoFaEnabled && is2FaVerified ? (
                    <>
                        <Route path="/argusma-words-panel" element={<AdminPanel />} />
                        <Route path="/argusma-words-panel/level" element={<LevelContainer />} />
                        <Route path="/argusma-words-panel/level/add" element={<NewLevel />} />
                        <Route path="/argusma-words-panel/level/update/:id" element={<UpdateLevel />} />
                        <Route path="/argusma-words-panel/user" element={<UserContainer />} />
                        <Route path="/argusma-words-panel/user/update/:id" element={<UpdateUser />} />
                        <Route path="/argusma-words-panel/user-level" element={<UserLevelContainer />} />
                        <Route path="/argusma-words-panel/user-level/update/:id" element={<UpdateUserLevel />} />
                        <Route path="/argusma-words-panel/language" element={<LanguageContainer />} />
                        <Route path="/argusma-words-panel/language/add" element={<NewLanguage />} />
                        <Route path="/argusma-words-panel/language/update/:id" element={<UpdateLanguage />} />
                        <Route path="/argusma-words-panel/letter-group" element={<LetterGroupContainer />} />
                        <Route path="/argusma-words-panel/letter-group/add" element={<NewLetterGroup />} />
                        <Route path="/argusma-words-panel/letter-group/update/:id" element={<UpdateLetterGroup />} />
                        <Route path="/argusma-words-panel/letter-group-level" element={<LetterGroupLevelContainer />} />
                        <Route path="/argusma-words-panel/letter-group-level/update/:id" element={<UpdateLetterGroupLevel />} />
                        <Route path="/argusma-words-panel/letter-group-word" element={<LetterGroupWordContainer />} />
                        <Route path="/argusma-words-panel/letter-group-word/update/:id" element={<UpdateLetterGroupWord />} />
                        <Route path="/argusma-words-panel/stage" element={<StageContainer />} />
                        <Route path="/argusma-words-panel/stage/add" element={<NewStage />} />
                        <Route path="/argusma-words-panel/stage/update/:id" element={<UpdateStage />} />
                        <Route path="/argusma-words-panel/word" element={<WordContainer />} />
                        <Route path="/argusma-words-panel/word/add" element={<NewWord />} />
                        <Route path="/argusma-words-panel/word/update/:id" element={<UpdateWord />} />
                        <Route path="*" element={<NotFound />} />
                    </>
                ) : null}

                {!wdmToken && (
                    <Route path="*" element={<Navigate to="/" />} />
                )}
            </Routes>
        </>
    );
};

export default RoutesContainer;
