import { createSlice } from "@reduxjs/toolkit";
import {
  asyncGetLanguages,
  asyncCreateLanguage,
  asyncUpdateLanguage,
  asyncDeleteLanguage,
} from "../../actions/admin/language/language.async.actions";
import {
  setFilters,
  clearFilters,
  setCurrentPage,
} from "../../actions/admin/language/language.sync.actions";

const languageAdminSlice = createSlice({
  name: "language",
  initialState: {
    languages: [],
    currentPage: 0,
    totalPages: 0,
    filters: {
      isActive: "",
      language: "",
    },
    isLoading: false,
    error: null,
    successMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // #region Async get
      .addCase(asyncGetLanguages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncGetLanguages.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;

        state.languages = content;
        state.currentPage = pageable.pageNumber;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetLanguages.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Async create
      .addCase(asyncCreateLanguage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncCreateLanguage.fulfilled, (state, action) => {
        state.languages.push(action.payload);
        state.isLoading = false;
        state.successMessage = "Language created successfully";
      })
      .addCase(asyncCreateLanguage.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Async update
      .addCase(asyncUpdateLanguage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncUpdateLanguage.fulfilled, (state, action) => {
        const index = state.languages.findIndex(
          (lang) => lang.id === action.payload.id
        );
        if (index !== -1) {
          state.languages[index] = action.payload;
        }
        state.isLoading = false;
        state.successMessage = "Language updated successfully";
      })
      .addCase(asyncUpdateLanguage.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Async delete
      .addCase(asyncDeleteLanguage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(asyncDeleteLanguage.fulfilled, (state, action) => {
        state.languages = state.languages.filter(
          (lang) => lang.id !== action.payload.id
        );
        state.isLoading = false;
        state.successMessage = "Language deleted successfully";
      })
      .addCase(asyncDeleteLanguage.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      // #endregion

      // #region Sync actions
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = { isActive: "", language: "" };
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
    // #endregion
  },
});

export default languageAdminSlice.reducer;
