import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { asyncGetWords, asyncUpdateWord } from '../../redux/actions/admin/word/word.async.actions';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UpdateWord = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { words } = useSelector((state) => state.wordAdminSlice);
  const [formData, setFormData] = useState({
    isActive: false,
    letters: '',
    idLanguage: '',
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (!words.length) {
      dispatch(asyncGetWords({ currentPage: 1, filters: {} }));
    } else {
      const word = words.find((w) => w.id === parseInt(id, 10));
      if (word) {
        setFormData({
          isActive: word.isActive,
          letters: word.letters,
          idLanguage: word.idLanguage,
        });
      }
    }
  }, [dispatch, id, words]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      await dispatch(asyncUpdateWord({ id, updatedWord: formData })).unwrap();
      navigate('/word');
    } catch (err) {
      console.error('Failed to update word:', err);
    }
  };

  const handleCancelUpdate = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="container mt-4">
      <h2>Edit Word</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="isActive" className="form-label">Is it active?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="isActive"
            name="isActive"
            checked={formData.isActive}
            onChange={(e) => handleChange({
              target: { name: 'isActive', value: e.target.checked }
            })}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="letters" className="form-label">Letters</label>
          <input
            required
            type="text"
            className="form-control"
            id="letters"
            name="letters"
            value={formData.letters}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="idLanguage" className="form-label">Language ID</label>
          <input
            required
            type="number"
            className="form-control"
            id="idLanguage"
            name="idLanguage"
            value={formData.idLanguage}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Update</button>
      </form>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleConfirmUpdate}
        onCancel={handleCancelUpdate}
      />
    </div>
  );
};

export default UpdateWord;
