import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import apiAxiosWithCredentials from '../../config/axios.config';
import { asyncVerifyTwoFA } from '../../redux/actions/common/user.async.actions';

const TwoFaForm = () => {
  const [code, setCode] = useState('');
  const [localError, setLocalError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username, error, isLoading } = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (error) {
      setCode('');
      setLocalError(error);
    }

    return () => {
      setLocalError('');
    };
  }, [error]);


  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (!username) {
      setLocalError('Username is not available.');
      return;
    }

    try {
      const result = await dispatch(asyncVerifyTwoFA({ otp: code, username })).unwrap();
      if (result.token) {
        apiAxiosWithCredentials.defaults.headers.common['Authorization'] = `Bearer ${result.token}`;
        navigate('/argusma-words-panel');
      }
    } catch (err) {
      setLocalError('OTP verification failed. Please try again.');
    }
  };


  return (
    <Form onSubmit={handleVerifyCode}>
      <Form.Group controlId="formCode" className="mb-3">
        <Form.Label>2 Factor Authentication Code</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your 2FA code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          disabled={isLoading}
        />
      </Form.Group>

      {localError && (
        <Alert variant="danger" onClose={() => setLocalError('')} dismissible>
          {localError}
        </Alert>
      )}

      <Button variant="primary" type="submit" disabled={isLoading || !code}>
        {isLoading ? 'Verifying...' : 'Send'}
      </Button>
    </Form>
  );
};

export default TwoFaForm;
