import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncCreateLevel } from '../../redux/actions/admin/level/level.async.actions';
import { asyncGetStages } from '../../redux/actions/admin/stage/stage.async.actions';
import { asyncGetLanguages } from '../../redux/actions/admin/language/language.async.actions';

const NewLevel = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { languages } = useSelector((state) => state.languageAdminSlice);
    const { stages } = useSelector((state) => state.stageAdminSlice);


    const [formData, setFormData] = useState({
        hint: '',
        assignment: '',
        time: 60,
        requiredLetterFlag: false,
        requiredLetter: '',
        checkCondition: '',
        isChill: false,
        idStage: '',
        idLanguage: '',
        numberOfWords: 0,
        numberOfLetters: 0,
        lettersAtLeast: false,
        endIsStart: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(asyncGetStages()).unwrap();
                await dispatch(asyncGetLanguages()).unwrap();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dispatch]);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        generateCheckCondition(name, type === 'checkbox' ? checked : value);
    };

    const generateCheckCondition = (name, value) => {
        const { numberOfWords, requiredLetterFlag, requiredLetter, numberOfLetters, lettersAtLeast, endIsStart } = {
            ...formData,
            [name]: value,
        };

        let conditions = [];
        if (numberOfWords > 0) {
            conditions.push(`words::${numberOfWords}`);
        }

        if (requiredLetterFlag && requiredLetter === 'first') {
            conditions.push('sameCharStart');
        } else if (requiredLetterFlag && requiredLetter === 'last') {
            conditions.push(endIsStart ? 'endIsStart' : 'sameCharEnd');
        }

        if (numberOfLetters > 0 && !requiredLetterFlag) {
            let lettersCondition = `letters::${numberOfLetters}`;
            if (lettersAtLeast) {
                lettersCondition += '+';
            }
            conditions.push(lettersCondition);
        }

        setFormData((prevData) => ({
            ...prevData,
            checkCondition: conditions.join('::'),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.idStage || !formData.idLanguage) {
            alert('Please select a valid stage and language.');
            return;
        }

        dispatch(asyncCreateLevel(formData))
            .unwrap()
            .then(() => navigate('/argusma-words-panel/level'))
            .catch((error) => console.error('Error creating level:', error));
    };

    return (
        <div className="container mt-4">
            <h2>Add New Level</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Hint (optional)</label>
                    <input type="text" className="form-control" id="hint" name="hint" value={formData.hint} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="idStage" className="form-label">Stage</label>
                    <select
                        className="form-select"
                        id="idStage"
                        name="idStage"
                        value={formData.idStage}
                        onChange={handleChange}
                    >
                        <option value="">Select a stage</option>
                        {stages.map((stage) => (
                            <option key={stage.id} value={stage.id}>{stage.letters}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="idLanguage" className="form-label">Language</label>
                    <select
                        className="form-select"
                        id="idLanguage"
                        name="idLanguage"
                        value={formData.idLanguage}
                        onChange={handleChange}
                    >
                        <option value="">Select a language</option>
                        {languages.map((language) => (
                            <option key={language.id} value={language.id}>{language.language}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Add Level</button>
            </form>
        </div>
    );
};

export default NewLevel;
