import PropTypes from 'prop-types';
import LetterGroupWordItem from './LetterGroupWordItem';

const LetterGroupWordList = ({ list, edit }) => {
  return (
    <>
      {list.map((item) => (
        <LetterGroupWordItem
          key={item.id}
          item={item}
          edit={edit}
        />
      ))}
    </>
  );
};

LetterGroupWordList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      idLetterGroup: PropTypes.number.isRequired,
      idWord: PropTypes.number.isRequired,
    })
  ).isRequired,
  edit: PropTypes.func.isRequired,
};

export default LetterGroupWordList;
