import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncCreateStage } from '../../redux/actions/admin/stage/stage.async.actions';

const NewStage = () => {
  const [formData, setFormData] = useState({
    letterCount: '',
    number: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(asyncCreateStage(formData)).unwrap();
      navigate('/argusma-words-panel/stage');
    } catch (err) {
      console.error('Failed to add stage:', err);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Add New Stage</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="letterCount" className="form-label">Letter count</label>
          <input
            required
            type="text"
            className="form-control"
            id="letterCount"
            name="letterCount"
            value={formData.letterCount}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="number" className="form-label">Number</label>
          <input
            required
            type="number"
            className="form-control"
            id="number"
            name="number"
            value={formData.number}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Stage</button>
      </form>
    </div>
  );
};

export default NewStage;
