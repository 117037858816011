import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { setTwoFaEnabled } from '../redux/reducers/common/user.reducer';
import { asyncLogin } from '../redux/actions/common/user.async.actions';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.userSlice);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError(null);

    try {
      const response = await dispatch(asyncLogin({ username, password })).unwrap();

      if (response.twoFaEnabled) {
       
        
        dispatch(setTwoFaEnabled(true));
        navigate('/argusma-words-panel/auth/set-qr-code');
      } else {
        setLoginError('Not authorized');
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginError('User or password not correct');
    }
  };

  return (
    <Form onSubmit={handleLogin}>
      <Form.Group controlId="formUsername">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your username or email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>

      {loginError && <Alert style={{ marginTop: '20px' }} variant="danger">{loginError}</Alert>}

      <Button
        variant="primary"
        type="submit"
        disabled={isLoading || !username || !password}
        style={{ marginTop: '20px' }}
      >
        {isLoading ? 'Logging in...' : 'Log in'}
      </Button>
    </Form>
  );
};

export default AdminLogin;
