import PropTypes from 'prop-types';

const LetterGroupLevelItem = ({ item, edit, confirmDelete }) => {
  return (
    <tr>
      <td>{item.used ? 'Yes' : 'No'}</td>
      <td>{item.idLetterGroup}</td>
      <td>{item.idLevel}</td>
      <td>
        <button className="btn btn-info btn-sm" onClick={() => edit(item.id)}>Edit</button>
        <button className="btn btn-danger btn-sm" onClick={() => confirmDelete(item.id)}>Delete</button>
      </td>
    </tr>
  );
};

LetterGroupLevelItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    used: PropTypes.bool.isRequired,
    idLetterGroup: PropTypes.number.isRequired,
    idLevel: PropTypes.number.isRequired,
  }).isRequired,
  edit: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default LetterGroupLevelItem;
