import { createAsyncThunk } from "@reduxjs/toolkit";
import apiAxiosWithCredentials from "../../../../config/axios.config";

export const asyncGetLetterGroupLevels = createAsyncThunk(
  "letterGroupLevel/asyncGetLetterGroupLevels",
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/letter-group-levels/cms/search/?page=${currentPage - 1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(
            value
          )}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const asyncUpdateLetterGroupLevel = createAsyncThunk(
  "letterGroupLevel/asyncUpdateLetterGroupLevel",
  async ({ id, updatedLetterGroupLevel }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(
        `/arguscreative/wordamundo/letter-group-levels/cms/${id}`,
        {
          letterGroupId: updatedLetterGroupLevel.idLetterGroup,
          levelId: updatedLetterGroupLevel.idLevel,
          used: updatedLetterGroupLevel.used,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const asyncDeleteLetterGroupLevel = createAsyncThunk(
  "letterGroupLevel/asyncDeleteLetterGroupLevel",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.delete(
        `/arguscreative/wordamundo/letter-group-levels/cms/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
