import { createAsyncThunk } from '@reduxjs/toolkit';
import apiAxiosWithCredentials from '../../../../config/axios.config';

export const asyncGetLevels = createAsyncThunk(
  'level/asyncGetLevels',
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/levels/cms/search/?page=${currentPage - 1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncCreateLevel = createAsyncThunk(
  'level/asyncCreateLevel',
  async (newLevel, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.post('/arguscreative/wordamundo/levels/cms', newLevel);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncUpdateLevel = createAsyncThunk(
  'level/asyncUpdateLevel',
  async ({ id, updatedLevel }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(`/arguscreative/wordamundo/levels/cms/${id}`, updatedLevel);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const asyncDeleteLevel = createAsyncThunk(
  'level/asyncDeleteLevel',
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.delete(`/arguscreative/wordamundo/levels/cms/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

