import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncGetLevels, asyncDeleteLevel } from '../../redux/actions/admin/level/level.async.actions';
import { setFilters, clearFilters, setCurrentPage } from '../../redux/actions/admin/level/level.sync.actions';
import LevelList from './LevelList';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const LevelContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { levels, currentPage, totalPages, filters, isLoading, error, successMessage } = useSelector((state) => state.levelAdminSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [levelToDelete, setLevelToDelete] = useState(null);

  useEffect(() => {
    dispatch(asyncGetLevels({ currentPage, filters }));
  }, [dispatch, currentPage, filters]);

  const handleFilterChange = (filter, value) => {
    const updatedFilters = { ...filters, [filter]: value };
    dispatch(setFilters(updatedFilters));
    dispatch(setCurrentPage(1));
    dispatch(asyncGetLevels({ currentPage: 1, filters: updatedFilters }));
  };

  const clearAllFilters = () => {
    dispatch(clearFilters());
    dispatch(setCurrentPage(1));
    dispatch(asyncGetLevels({ currentPage: 1, filters: {} }));
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const editLevel = (id) => {
    navigate(`/argusma-words-panel/level/update/${id}`);
  };

  const confirmDeleteLevel = (id) => {
    setLevelToDelete(id);
    setShowConfirmation(true);
  };

  const handleDeleteLevel = () => {
    if (levelToDelete) {
      dispatch(asyncDeleteLevel(levelToDelete))
        .then(() => {
          dispatch(asyncGetLevels({ currentPage, filters }));
          setShowConfirmation(false);
          setLevelToDelete(null);
        });
    }
  };

  const cancelDeleteLevel = () => {
    setShowConfirmation(false);
    setLevelToDelete(null);
  };

  return (
    <div className="container mt-4">
      <h2>Levels</h2>

      {isLoading && <p>Loading...</p>}
      {error && <p className="text-danger">Error: {error}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}

      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
        <button className="btn btn-primary" onClick={() => navigate('/argusma-words-panel/level/add')}>Add New</button>
      </div>

      <div className="mb-3">
        <label htmlFor="filterHint" className="form-label">Filter by Hint</label>
        <input
          type="text"
          id="filterHint"
          className="form-control"
          value={filters.hint || ''}
          onChange={(e) => handleFilterChange('hint', e.target.value)}
          placeholder="Enter hint"
        />
      </div>

      <LevelList
        list={levels}
        editLevel={editLevel}
        confirmDeleteLevel={confirmDeleteLevel}
      />

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1 || isLoading}>Prev</button>
        <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages || isLoading}>Next</button>
      </div>
      <div className="mt-3">
        Page {currentPage} of {totalPages}
      </div>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleDeleteLevel}
        onCancel={cancelDeleteLevel}
      />
    </div>
  );
};

export default LevelContainer;
