import PropTypes from 'prop-types';

const LevelItem = ({ item, editLevel, confirmDeleteLevel }) => {
  return (
    <tr>
      <td>{item.isActive ? 'Yes' : 'No'}</td>
      <td>{item.number}</td>
      <td>{item.hint}</td>
      <td>{item.assignment}</td>
      <td>{item.time}</td>
      <td>{item.requiredLetterFlag ? 'Yes' : 'No'}</td>
      <td>{item.requiredLetter}</td>
      <td>{item.checkCondition}</td>
      <td>{item.isChill ? 'Yes' : 'No'}</td>
      <td>{item.stage}</td>
      <td>{item.language}</td>
      <td>
        <button className="btn btn-info btn-sm" onClick={() => editLevel(item.id)}>Edit</button>
        <button className="btn btn-danger btn-sm" onClick={() => confirmDeleteLevel(item.id)}>Delete</button>
      </td>
    </tr>
  );
};

LevelItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    hint: PropTypes.string,
    assignment: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
    requiredLetterFlag: PropTypes.bool,
    requiredLetter: PropTypes.string,
    checkCondition: PropTypes.string,
    isChill: PropTypes.bool,
    number: PropTypes.number,
    stage: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  editLevel: PropTypes.func.isRequired,
  confirmDeleteLevel: PropTypes.func.isRequired,
};

export default LevelItem;
