import PropTypes from 'prop-types';

const LanguageItem = ({ item, editLanguage, confirmDeleteLanguage }) => {
  return (
    <tr>
      <td>{item.isActive ? 'Yes' : 'No'}</td>
      <td>{item.language}</td>
      <td>
        <button className="btn btn-info btn-sm" onClick={() => editLanguage(item.id)}>Edit</button>
        <button className="btn btn-danger btn-sm" onClick={() => confirmDeleteLanguage(item.id)}>Delete</button>
      </td>
    </tr>
  );
};

LanguageItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  editLanguage: PropTypes.func.isRequired,
  confirmDeleteLanguage: PropTypes.func.isRequired,
};

export default LanguageItem;
