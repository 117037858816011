import { createSlice } from '@reduxjs/toolkit';
import {
  asyncGetLetterGroupWords,
  asyncGetLetterGroupWordById,
  asyncUpdateLetterGroupWord,
  asyncDeleteLetterGroupWord
} from '../../actions/admin/letterGroupWord/letterGroupWord.async.actions';
import { setFilters, clearFilters, setCurrentPage } from '../../actions/admin/letterGroupWord/letterGroupWord.sync.actions';

const initialState = {
  letterGroupWords: [],
  currentLetterGroupWord: null,
  currentPage: 1,
  totalPages: 0,
  filters: {
    idLetterGroup: '',
    idWord: ''
  },
  isLoading: false,
  error: null,
  successMessage: null
};

const letterGroupWordAdminSlice = createSlice({
  name: 'letterGroupWord',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetLetterGroupWords.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncGetLetterGroupWords.fulfilled, (state, action) => {
        const { content, pageable, totalPages } = action.payload;
        state.letterGroupWords = content;
        state.currentPage = pageable.pageNumber + 1;
        state.totalPages = totalPages;
        state.isLoading = false;
      })
      .addCase(asyncGetLetterGroupWords.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      
      .addCase(asyncGetLetterGroupWordById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncGetLetterGroupWordById.fulfilled, (state, action) => {
        state.currentLetterGroupWord = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncGetLetterGroupWordById.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

 

      .addCase(asyncUpdateLetterGroupWord.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncUpdateLetterGroupWord.fulfilled, (state, action) => {
        const index = state.letterGroupWords.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.letterGroupWords[index] = action.payload;
        }
        state.isLoading = false;
        state.successMessage = 'Letter Group-Word updated successfully';
      })
      .addCase(asyncUpdateLetterGroupWord.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(asyncDeleteLetterGroupWord.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncDeleteLetterGroupWord.fulfilled, (state, action) => {
        state.letterGroupWords = state.letterGroupWords.filter(item => item.id !== action.payload.id);
        state.isLoading = false;
        state.successMessage = 'Letter Group-Word deleted successfully';
      })
      .addCase(asyncDeleteLetterGroupWord.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
        state.currentPage = 1;
      })
      .addCase(clearFilters, (state) => {
        state.filters = initialState.filters;
        state.currentPage = 1;
      })
      .addCase(setCurrentPage, (state, action) => {
        state.currentPage = action.payload;
      });
  }
});

export default letterGroupWordAdminSlice.reducer;
