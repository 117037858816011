import { createAsyncThunk } from "@reduxjs/toolkit";
import apiAxiosWithCredentials from "../../../../config/axios.config";

export const asyncGetLanguages = createAsyncThunk(
  "language/asyncGetLanguages",
  async ({ currentPage, filters }, { rejectWithValue }) => {
    try {
      let apiUrlWithParams = `/arguscreative/wordamundo/languages/cms/search/?page=${currentPage-1}`;
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrlWithParams += `&${encodeURIComponent(key)}=${encodeURIComponent(
            value
          )}`;
        }
      });

      const response = await apiAxiosWithCredentials.get(apiUrlWithParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const asyncCreateLanguage = createAsyncThunk(
  "language/asyncCreateLanguage",
  async (newLanguage, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.post(
        "/arguscreative/wordamundo/languages/cms",
        newLanguage
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const asyncUpdateLanguage = createAsyncThunk(
  "language/asyncUpdateLanguage",
  async ({ id, updatedLanguage }, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.put(
        `/arguscreative/wordamundo/languages/cms/${id}`,
        updatedLanguage
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const asyncDeleteLanguage = createAsyncThunk(
  "language/asyncDeleteLanguage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiAxiosWithCredentials.delete(
        `/arguscreative/wordamundo/languages/cms/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
