import { Container } from 'react-bootstrap';
import Footer from '../components/footer/Footer';
import PublicNavbar from '../components/publicNavbar/PublicNavbar';

const AboutUs = () => {
  return (
    <Container fluid className="p-0">
      <PublicNavbar />
      {/* Hero Section */}
      <div
        style={{
          background: 'linear-gradient(135deg, #1e3c72, #2a5298)',
          color: 'white',
          padding: '100px 20px',
          textAlign: 'center',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
        }}
      >

        <h1
          style={{
            fontWeight: '700',
            fontSize: '2.5rem',
            textShadow: '2px 2px 8px rgba(0,0,0,0.3)',
          }}
        >
          About Us
        </h1>
      </div>

      {/* Content Section */}
      <Container
        className="my-5"
        style={{
          padding: '40px 20px',
          maxWidth: '800px',
          backgroundColor: '#f8f9fa',
          borderRadius: '15px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            color: '#333',
            marginBottom: '20px',
          }}
        >
          Welcome to Wordamundo, where creativity meets a love affair with words! 🎉
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            color: '#333',
            marginBottom: '20px',
          }}
        >
          We’re not just a team; we’re a quirky squad of cool-thing creators and
          word wizards. Our mission? To transform your everyday browsing into an
          adventure filled with fun, laughter, and a sprinkle of knowledge.
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            color: '#333',
            marginBottom: '20px',
          }}
        >
          Imagine a place where every click is a gateway to a delightful
          discovery, where learning feels like a joyous game, and where you
          can’t help but smile at our playful puns and witty wordplay. That’s us
          in a nutshell!
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            color: '#333',
            marginBottom: '20px',
          }}
        >
          Whether you’re here to explore, learn, or just have a good time, we’ve
          got you covered. We pour our hearts into crafting an experience that’s
          as engaging as a good story and as fun as a day at the carnival.
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            color: '#333',
            marginBottom: '20px',
          }}
        >
          Dive in, enjoy the ride, and let’s make every visit a memorable one.
          After all, life’s too short for boring, and we’re here to keep you
          coming back for more smiles, surprises, and smarts! 🚀
        </p>

        <h2
          style={{
            marginTop: '40px',
            fontSize: '1.8rem',
            fontWeight: '700',
            color: '#2a5298',
            textAlign: 'center',
          }}
        >
          Acknowledgments
        </h2>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            color: '#333',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          This application utilizes WordNet®, a lexical database for the English
          language developed by Princeton University.
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            color: '#333',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Reference: George A. Miller (1995). WordNet: A Lexical Database for
          English. <i>Communications of the ACM</i> Vol. 38, No. 11: 39-41.
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8',
            color: '#2a5298',
            textAlign: 'center',
          }}
        >
          For more information, please visit the official{' '}
          <a
            href="https://wordnet.princeton.edu"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1e90ff', 
              fontWeight: 'bold', 
              textDecoration: 'none',
            }}
            onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
            onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
          >
            WordNet website
          </a>
          .
        </p>

      </Container>

      <Footer />
    </Container>
  );
};

export default AboutUs;
