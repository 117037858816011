import PropTypes from 'prop-types';
import UserItem from './UserItem';

const UserList = ({ list, editUser, confirmDeleteUser }) => {
  return (
    <table className="table table-striped table-bordered custom-table">
      <thead className="thead-dark">
        <tr>
          <th>Is Active?</th>
          <th>Email</th>
          <th>Username</th>
          <th>Role</th>
          <th>Coins</th>
          <th>Valid Words</th>
          <th>Invalid Words</th>
          <th>Duplicate Words</th>
          <th>Apple ID</th>
          <th>Google ID</th>
          <th>First Load?</th>
          <th>2FA?</th>
          <th>Max Level</th>
          <th>Total Score</th>
          <th>Language ID</th>
          <th>Previous Username</th>
          <th>Created At</th>
          <th>Updated At</th>
          <th>Deleted At</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <UserItem
            key={item.id}
            item={item}
            editUser={editUser}
            confirmDeleteUser={confirmDeleteUser}
          />
        ))}
      </tbody>
    </table>
  );
};

UserList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      userRole: PropTypes.string.isRequired,
      coinCount: PropTypes.number,
      validWordCount: PropTypes.number,
      invalidWordCount: PropTypes.number,
      duplicateWordCount: PropTypes.number,
      appleId: PropTypes.string,
      googleId: PropTypes.string,
      isActive: PropTypes.bool,
      firstLoad: PropTypes.bool,
      isTwoFA: PropTypes.bool,
      maxLevel: PropTypes.number,
      totalScore: PropTypes.number,
      languageId: PropTypes.number,
      previousUsername: PropTypes.string,
      createdAt: PropTypes.any,
      updatedAt: PropTypes.any,
      deletedAt: PropTypes.any,
    })
  ).isRequired,
  editUser: PropTypes.func.isRequired,
  confirmDeleteUser: PropTypes.func.isRequired,
};

export default UserList;
