import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncGetLetterGroupWords } from '../../redux/actions/admin/letterGroupWord/letterGroupWord.async.actions';
import { setFilters, clearFilters, setCurrentPage } from '../../redux/actions/admin/letterGroupWord/letterGroupWord.sync.actions';
import LetterGroupWordList from './LetterGroupWordList';

const LetterGroupWordContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { letterGroupWords, currentPage, totalPages, filters, isLoading } = useSelector((state) => state.letterGroupWordAdminSlice);

    useEffect(() => {
        dispatch(asyncGetLetterGroupWords({ currentPage, filters }));
    }, [dispatch, currentPage, filters]);

    const handleFilterChange = (filter, value) => {
        const updatedFilters = { ...filters, [filter]: value };
        dispatch(setFilters(updatedFilters));
        dispatch(setCurrentPage(1));
        dispatch(asyncGetLetterGroupWords({ currentPage: 1, filters: updatedFilters }));
    };

    const clearAllFilters = () => {
        dispatch(clearFilters());
        dispatch(setCurrentPage(1));
        dispatch(asyncGetLetterGroupWords({ currentPage: 1, filters: {} }));
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            dispatch(setCurrentPage(currentPage + 1));
        }
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            dispatch(setCurrentPage(currentPage - 1));
        }
    };

    const editLetterGroupWord = (id) => {
        navigate(`/argusma-words-panel/letter-group-word/update/${id}`);
    };

    return (
        <div className="container mt-4">
            <h2>Letter Group-Word</h2>

            <div className="d-flex justify-content-between mb-3">
                <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
            </div>

            <div className="mb-3">
                <label htmlFor="filterLetterGroupId" className="form-label">Filter by Letter Group ID</label>
                <input
                    type="text"
                    id="filterLetterGroupId"
                    className="form-control"
                    value={filters.idLetterGroup || ''}
                    onChange={(e) => handleFilterChange('idLetterGroup', e.target.value)}
                    placeholder="Enter Letter Group ID"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="filterWordId" className="form-label">Filter by Word ID</label>
                <input
                    type="text"
                    id="filterWordId"
                    className="form-control"
                    value={filters.idWord || ''}
                    onChange={(e) => handleFilterChange('idWord', e.target.value)}
                    placeholder="Enter Word ID"
                />
            </div>

            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <table className="table table-striped table-bordered custom-table">
                    <thead className="thead-dark">
                        <tr>
                            <th>Letter Group ID</th>
                            <th>Word ID</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <LetterGroupWordList list={letterGroupWords} edit={editLetterGroupWord} />
                    </tbody>
                </table>
            )}

            <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1 || isLoading}>Prev</button>
                <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages || isLoading}>Next</button>
            </div>
            <div className="mt-3">
                Page {currentPage} of {totalPages}
            </div>
        </div>
    );
};

export default LetterGroupWordContainer;
