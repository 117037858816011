import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { asyncGetStages, asyncUpdateStage } from '../../redux/actions/admin/stage/stage.async.actions';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UpdateStage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stages } = useSelector((state) => state.stageAdminSlice);
  const [formData, setFormData] = useState({
    isActive: false,
    letterCount: '',
    number: '',
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (!stages.length) {
      dispatch(asyncGetStages({ currentPage: 1, filters: {} }));
    } else {
      const stage = stages.find((s) => s.id === parseInt(id, 10));
      if (stage) {
        setFormData({
          isActive: stage.isActive,
          letterCount: stage.letterCount,
          number: stage.number,
        });
      }
    }
  }, [dispatch, id, stages]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      await dispatch(asyncUpdateStage({ id, updatedStage: formData })).unwrap();
      navigate('/argusma-words-panel/stage');
    } catch (err) {
      console.error('Failed to update stage:', err);
    }
  };

  const handleCancelUpdate = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="container mt-4">
      <h2>Edit Stage</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="isActive" className="form-label">Is it active?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="isActive"
            name="isActive"
            checked={formData.isActive}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="letterCount" className="form-label">Letter count</label>
          <input
            required
            type="text"
            className="form-control"
            id="letterCount"
            name="letterCount"
            value={formData.letterCount}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="number" className="form-label">Number</label>
          <input
            required
            type="number"
            className="form-control"
            id="number"
            name="number"
            value={formData.number}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Update</button>
      </form>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleConfirmUpdate}
        onCancel={handleCancelUpdate}
      />
    </div>
  );
};

export default UpdateStage;
