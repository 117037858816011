import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { asyncGetUsers, asyncUpdateUser } from '../../redux/actions/admin/user/user.async.actions';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UpdateUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, isLoading, error } = useSelector((state) => state.userAdminSlice);

  const [formData, setFormData] = useState({
    isActive: false,
    email: '',
    username: '',
    avatar: '',
    role: '',
    diamonds: 0,
    coins: 0,
    validWords: 0,
    invalidWords: 0,
    duplicateWords: 0,
    firstLoad: false,
    appleId: '',
    googleId: '',
    idLanguage: 0,
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  useEffect(() => {
    if (id && !users.length) {
      dispatch(asyncGetUsers({ currentPage: 1, filters: {} }));
    } else if (id && users.length) {
      const userToEdit = users.find((user) => user.id === parseInt(id, 10));
      if (userToEdit) {
        setFormData(userToEdit);
      }
    }
  }, [dispatch, id, users]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = async () => {
    setIsUpdating(true);
    setUpdateError(null);
    try {
      await dispatch(asyncUpdateUser({ id, updatedUser: formData })).unwrap();
      setShowConfirmation(false);
      navigate('/argusma-words-panel/user');
    } catch (err) {
      setUpdateError('Failed to update user. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCancelUpdate = () => {
    setShowConfirmation(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading user: {error}</div>;
  }

  return (
    <div className="container mt-4">
      <h2>Edit User</h2>

      {updateError && <p className="text-danger">{updateError}</p>}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="isActive" className="form-label">Is Active?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="isActive"
            name="isActive"
            checked={formData.isActive}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            required
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">Username</label>
          <input
            required
            type="text"
            className="form-control"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="avatar" className="form-label">Avatar</label>
          <input
            type="text"
            className="form-control"
            id="avatar"
            name="avatar"
            value={formData.avatar}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="role" className="form-label">Role</label>
          <select
            className="form-control"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
          >
            <option value="">Select Role</option>
            <option value="common">Common</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="diamonds" className="form-label">Diamonds</label>
          <input
            type="number"
            className="form-control"
            id="diamonds"
            name="diamonds"
            value={formData.diamonds}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="coins" className="form-label">Coins</label>
          <input
            type="number"
            className="form-control"
            id="coins"
            name="coins"
            value={formData.coins}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="validWords" className="form-label">Valid Words</label>
          <input
            type="number"
            className="form-control"
            id="validWords"
            name="validWords"
            value={formData.validWords}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="invalidWords" className="form-label">Invalid Words</label>
          <input
            type="number"
            className="form-control"
            id="invalidWords"
            name="invalidWords"
            value={formData.invalidWords}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="duplicateWords" className="form-label">Duplicate Words</label>
          <input
            type="number"
            className="form-control"
            id="duplicateWords"
            name="duplicateWords"
            value={formData.duplicateWords}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="firstLoad" className="form-label">Is First Load?</label>
          <input
            type="checkbox"
            className="form-check-input"
            id="firstLoad"
            name="firstLoad"
            checked={formData.firstLoad}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="appleId" className="form-label">Apple ID</label>
          <input
            type="text"
            className="form-control"
            id="appleId"
            name="appleId"
            value={formData.appleId}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="googleId" className="form-label">Google ID</label>
          <input
            type="text"
            className="form-control"
            id="googleId"
            name="googleId"
            value={formData.googleId}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="idLanguage" className="form-label">Language ID</label>
          <input
            type="number"
            className="form-control"
            id="idLanguage"
            name="idLanguage"
            value={formData.idLanguage}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={isUpdating}>
          {isUpdating ? 'Updating...' : 'Update'}
        </button>
      </form>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleConfirmUpdate}
        onCancel={handleCancelUpdate}
      />
    </div>
  );
};

export default UpdateUser;
