import  { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  asyncGetStages,
  asyncDeleteStage
} from '../../redux/actions/admin/stage/stage.async.actions';
import { setFilters, clearFilters, setCurrentPage } from '../../redux/actions/admin/stage/stage.sync.actions';
import StageList from './StageList';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const StageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stages, currentPage, totalPages, filters, isLoading, error, successMessage } = useSelector((state) => state.stageAdminSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [stageToDelete, setStageToDelete] = useState(null);

  useEffect(() => {
    dispatch(asyncGetStages({ currentPage, filters }));
  }, [dispatch, currentPage, filters]);

  const handleFilterChange = (filter, value) => {
    dispatch(setFilters({ ...filters, [filter]: value }));
    dispatch(setCurrentPage(1));
  };

  const clearAllFilters = () => {
    dispatch(clearFilters());
    dispatch(setCurrentPage(1));
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const edit = (id) => {
    navigate(`/argusma-words-panel/stage/update/${id}`);
  };

  const confirmDelete = (id) => {
    setStageToDelete(id);
    setShowConfirmation(true);
  };

  const handleDeleteStage = () => {
    if (stageToDelete) {
      dispatch(asyncDeleteStage(stageToDelete))
        .then(() => {
          dispatch(asyncGetStages({ currentPage, filters }));
          setShowConfirmation(false);
          setStageToDelete(null);
        });
    }
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setStageToDelete(null);
  };

  return (
    <div className="container mt-4">
      <h2>Stages</h2>

      {isLoading && <p>Loading...</p>}
      {error && <p className="text-danger">Error: {error}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}

      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
        <button className="btn btn-primary" onClick={() => navigate('/argusma-words-panel/stage/add')}>Add New</button>
      </div>

      <div className="mb-3">
        <label htmlFor="filterIsActive" className="form-label">Filter by Active Status</label>
        <select
          id="filterIsActive"
          className="form-select"
          value={filters.isActive}
          onChange={(e) => handleFilterChange('isActive', e.target.value)}
        >
          <option value="">All</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="filterLetters" className="form-label">Filter by letter count</label>
        <input
          type="text"
          id="filterLetters"
          className="form-control"
          value={filters.letterCount}
          onChange={(e) => handleFilterChange('letterCount', e.target.value)}
          placeholder="Enter letter count"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="filterNumber" className="form-label">Filter by Number</label>
        <input
          type="number"
          id="filterNumber"
          className="form-control"
          value={filters.number}
          onChange={(e) => handleFilterChange('number', e.target.value)}
          placeholder="Enter number"
        />
      </div>

      <table className="table table-striped table-bordered custom-table">
        <thead className="thead-dark">
          <tr>
            <th>Is Active?</th>
            <th>Letter Count</th>
            <th>Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <StageList list={stages} edit={edit} confirmDelete={confirmDelete} />
        </tbody>
      </table>

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1}>Prev</button>
        <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button>
      </div>
      <div className="mt-3">
        Page {currentPage} of {totalPages}
      </div>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onConfirm={handleDeleteStage}
        onCancel={cancelDelete}
      />
    </div>
  );
};

export default StageContainer;
